export default {
  pop: {
    value: '5,353,365',
    reference: {
      text: 'The Global Cancer Observatory (2018), Norway, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/578-norway-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '45.353',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 85.47,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 14.18,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '8,806',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '11,717',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: false,
    reference: {
      text: 'Biobank Norway, Norwegian health data, viewed 21 August 2020',
      link: 'https://bbmri.no/health-data'
    }
  },
  reg_sbs_if: {
    value: false,
    reference: {
      text: 'Biobank Norway, Norwegian health data, viewed 21 August 2020',
      link: 'https://bbmri.no/health-data'
    }
  },
  reg_hpn: {
    value: false,
    reference: {
      text: 'Biobank Norway, Norwegian health data, viewed 21 August 2020',
      link: 'https://bbmri.no/health-data'
    }
  },
  ref_centers: {
    value: null,
    reference: null
  },
  research_liver: {
    value: 0,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 17,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 2,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Norilco',
      type: '<abbr title="Gastrointestinal">GI</abbr>',
      reference: {
        text: 'NORILCO. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.norilco.no'
      }
    },
    {
      name: 'NIFO, Nosrk Intravenøs forening',
      type: '<abbr title="Home Parenteral Nutrition">HPN</abbr> (<abbr title="Intravenous">IV</abbr> general)',
      reference: {
        text: 'Norsk Intravenøsforening. 2018. Homepage. Viewed 21 August 2020',
        link: 'https://www.nifo.no'
      }
    },
    {
      name: 'Landsforeningen mot fordøyelsessykdommer – LMF Norge',
      type: '<abbr title="Gastrointestinal">GI</abbr>',
      reference: {
        text: 'Landsforeningen mot fordøyelsessykdommer. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.lmfnorge.no'
      }
    }
  ],
  hpn_available: {
    value: null,
    reference: null
  },
  hpn_reimbursed: {
    value: null,
    reference: null
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
