<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <div class="info-graphics">
      <div class="vertical-stretch">
        <div class="info-graphic">
          <h2>Clinical trials</h2>
          <span class="sub-title">Available in English</span>
          <div class="image">
            <a target="_blank" href="/files/clinical-trails.pdf" class="download" title="English">
              <img srcset="/images/pages/comparisons/clinical-trails@2x.jpg 2x, /images/pages/comparisons/clinical-trails.jpg 1x" src="/images/pages/comparisons/clinical-trails.jpg" alt="Infographics for HCPs" />
              <span>Download as PDF</span></a>
            <p>Access to clinical trials for intestinal failure (IF) and short bowel syndrome with intestinal failure (SBS-IF)
              versus renal failure and liver failure varies greatly across Europe. When comparing these three organ
              failures, it shows that renal failure has the most clinical trials present in Europe, although there are
              country specific differences. IF is a rare, unrecognised organ failure disease, and as a result there
              are low amount of clinical trials across Europe, potentially due to the small patient population. Both
              France and Denmark have the most clinical trial sites. In addition, from the 24 countries researched, 13
              did not have any IF clinical trial sites. On the contrary, renal failure has clinical trial sites in all 24
              countries evaluated, whilst liver failure has only five countries without clinical trial sites.</p>
            <p>We urge HCPs and patient groups to collaborate to increase IF clinical trial sites throughout Europe,
              ensuring patients a quality standard of care across all European countries.</p>
            <a target="_blank" href="/files/clinical-trails.pdf" class="button" title="English">Download</a>
          </div>
        </div>
        <div class="info-graphic">
          <h2>Disease prevalence</h2>
          <span class="sub-title">Available in English</span>
          <div class="image">
            <a target="_blank" href="/files/disease-prevalence.pdf" class="download" title="English">
              <img srcset="/images/pages/comparisons/disease-prevalence@2x.jpg 2x, /images/pages/comparisons/disease-prevalence.jpg 1x" src="/images/pages/comparisons/disease-prevalence.jpg" alt="Infographics for HCPs" />
              <span>Download as PDF</span>
            </a>
            <p>The above map of Europe gives an overview of the available prevalence data when comparing IF/SBS-IF,
              renal failure and liver failure. Chronic liver failure across Europe has the largest prevalence, followed by
              chronic kidney failure and IF/SBS-IF. There is a significant lack of local IF data in some European
              countries, resulting in 13 out of 24 countries not having relevant peer reviewed data that could be used.
              One of the <router-link :to="{ name: 'Needs' }">unmet needs</router-link> developed by the <router-link :to="{ name: 'Committee' }">ATLAS Steering Committee</router-link> is the need to disseminate agreed
              epidemiology and quality standards within and between countries, this would as a result help with the
              classification of the disease as well as measuring the impact of IF on the society as a whole.</p>
            <a target="_blank" href="/files/disease-prevalence.pdf" class="button" title="English">Download</a>
          </div>
        </div>
      </div>
    </div>
    <p style="font-size: 12px; line-height: 16px">Some of the data points may change over time in the original public source (for example, population),
      and therefore an audit trail is available on request providing evidence of the data point value at the time
      of access.</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: 'Comparing Clinical Trials and Prevalence in IF and Other Disease Areas Across Europe',
      description: 'Comparing Clinical Trials and Prevalence in IF and Other Disease Areas Across Europe',
      keywords: ''
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-graphics {
    margin-bottom: 20px;

    .info-graphic {
      max-width: 100%;
    }

    a.download {
      margin-bottom: 0;
    }

    .image {
      margin-bottom: 0;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      text-align: justify;

      &:first-of-type {
        margin-top: 30px;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    .info-graphics {
      .info-graphic {
        max-width: 49%;
      }
    }
  }
</style>
