export default {
  pop: {
    value: '19,580,628',
    reference: {
      text: 'The Global Cancer Observatory (2018), Romania, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/642-romania-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '12.384',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 78.59,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 20.49,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '11,906',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '18,806',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: null,
    reference: null
  },
  research_liver: {
    value: 5,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 37,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [],
  hpn_available: {
    value: false,
    reference: {
      text: 'JOHNSON, B. (2018). Improving standards of care in Chronic Intestinal Failure. The Parliament Magazine. 2018-04-23. Viewed 21 August 2020',
      link: 'https://www.theparliamentmagazine.eu/news/article/improving-standards-of-care-in-chronic-intestinal-failure'
    }
  },
  hpn_reimbursed: {
    value: null,
    reference: null
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
