<template>
    <footer>
      <div class="inner-wrapper">
        <div class="column">
          <nav class="footer-menu">
            <ul>
              <li><a rel="nofollow" href="https://www.takeda.com/terms-of-use/" target="_blank">Terms and conditions</a></li>
              <li><a rel="nofollow" href="https://www.takeda.com/privacy-notice/" target="_blank">Privacy</a></li>
              <li><a rel="nofollow" href="https://www.takeda.com/terms-of-use/" target="_blank">Disclaimers</a></li>
              <li><router-link :to="{ name: 'Cookies' }">Cookies</router-link></li>
            </ul>
          </nav>
          <p>The information on this website is intended for an audience outside of the USA/UK only. The website has been developed by Takeda in accordance with industry and legal standards about intestinal failure health topics. Takeda makes every reasonable effort to include accurate and current information. However, the information provided on the website is not exhaustive.</p>
          <p>This information is available to the public for information purposes only; it should not be used for diagnosing or treating a health problem or disease. It is not intended to substitute for consultation with a healthcare provider. <strong>Please consult your healthcare provider for further advice.</strong></p>
          <p>Copyright {{ (new Date()).getFullYear() }} Takeda Pharmaceutical Company Limited. All rights reserved. Takeda and the Takeda Logo are registered trademarks of Takeda Pharmaceutical Company Limited.</p>
          <p>C-ANPROM/EUC/SBS/0038<span class="last-updated">May 2021</span></p>
        </div>
        <div class="column">
          <nav>
            <ul>
              <li><a target="_blank" @click="externalURL" rel="nofollow" href="https://twitter.com/TakedaPharma"><div class="white icon icon-twitter"></div></a></li>
              <li><a target="_blank" @click="externalURL" rel="nofollow" href="https://www.linkedin.com/company/takeda-pharmaceuticals/"><div class="white icon icon-linkedin"></div></a></li>
              <li><a target="_blank" @click="externalURL" rel="nofollow" href="https://www.instagram.com/rare2aware"><div class="white icon icon-instagram"></div></a></li>
              <li><a target="_blank" @click="externalURL" rel="nofollow" href="https://www.facebook.com/Rare2Aware"><div class="white icon icon-facebook"></div></a></li>
            </ul>
          </nav>
          <div class="takeda">
            <router-link :to="{ name: 'About' }"><img src="/images/takeda.svg" :alt="takeda" /></router-link>
          </div>
        </div>
      </div>
      <div class="bar green"></div>
      <div class="bar blue"></div>
    </footer>
</template>

<script>
import { takeda } from '@/variables.js'
import externalURL from '@/external-url'

export default {
  data () {
    return {
      takeda
    }
  },
  methods: {
    externalURL
  }
}
</script>

<style lang="scss">
  footer {
    background: $darkBlue;
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .inner-wrapper {
      flex-grow: 1;
      padding-top: 35px;
      padding-bottom: 10px;
      justify-content: space-between;

      .column:last-child {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }

      p {
        font-size: 12px;
        line-height: inherit;
        color: $white;
      }

      .last-updated {
        float: right;
      }

      nav {
        margin-bottom: 15px;

        ul {
          display: inline-flex;

          li {
            list-style: none;

            a {
              color: $white;
              text-decoration: none;
              display: inline-block;
            }

            + li {
              margin-left: 10px;

              &:before {
                content: '|';
                display: inline-block;
                margin-right: 10px;
                color: $white;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }

    .takeda {
      flex-grow: 1;
      display: flex;
      align-self: center;

      a {
        display: block;
        align-self: flex-end;
      }

      img {
        width: 125px;
        margin-bottom: 8px;
      }
    }

    .bar {
      align-self: flex-end;
    }
  }

  @media screen and (max-width: 549px) {
    footer .inner-wrapper nav.footer-menu ul {
      display: block;
      text-align: center;

      li + li {
        margin: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: $tablet) {
    footer {
      .inner-wrapper {
        flex-grow: 1;
        padding-top: 25px;
        display: flex;

        .column {
          max-width: 75%;
        }

        .column:last-child {
          flex-shrink: 0;
          width: auto;
          display: flex;
          align-items: inherit;
          flex-direction: column;
          padding-left: 20px;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
