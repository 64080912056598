<template>
    <div id="app">
      <a href="#main-content" style="display: none">Skip to content</a>
      <atlas-header></atlas-header>
      <div class="inner-wrapper" id="main-content" style="flex-grow: 1">
        <router-view />
      </div>
      <atlas-pre-footer v-if="this.$route.name !== 'Home'"></atlas-pre-footer>
      <atlas-footer></atlas-footer>
      <cookie-law theme="takeda" :buttonText="'Accept'">
        <div slot="message">
          <p>The site uses cookies to provide you with a more responsive and personalised service. By using this site you agree to our use of cookies as set out in our privacy notice. <a href="https://www.takeda.com/privacy-notice/" target="_blank" rel="nofollow">Please read our privacy notice</a> for more information on the cookies we use and how to delete or block the use of cookies.</p>
        </div>
      </cookie-law>
      <div id="external-warning" v-if="externalWarning !== null">
        <div class="text">
          You're now being transferred to <span>{{ externalWarning.name }}</span> and are leaving <span>atlasif.eu</span>. Takeda has no influence or control over the content of this third-party website.<br />
          <a id="external-link-url" :href="externalWarning.url" rel="nofollow" target="_blank" @click="resetExternalWarning">If it takes longer than {{ sec }} seconds click here</a>
        </div>
      </div>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import PreFooter from './components/PreFooter'
import { absoluteURL, atlas } from '@/variables.js'
import CookieLaw from 'vue-cookie-law'

export default {
  data () {
    return {
      sec: 5
    }
  },
  methods: {
    resetExternalWarning () {
      this.$store.dispatch('setExternalWarning', null)
    }
  },
  computed: {
    externalWarning () {
      return this.$store.state.externalWarning
    }
  },
  components: {
    CookieLaw,
    atlasHeader: Header,
    atlasPreFooter: PreFooter,
    atlasFooter: Footer
  },
  watch: {
    externalWarning () {
      if (this.externalWarning === null) {
        return
      }
      setTimeout(() => {
        window.open(this.externalWarning.url, this.externalWarning.target)
        this.resetExternalWarning()
      }, this.sec * 1000)
    },
    $route () {
      this.resetExternalWarning()
    }
  },
  metaInfo () {
    return {
      titleTemplate: '%s | ' + atlas,
      meta: [
        { property: 'og:site_name', content: atlas },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: absoluteURL + window.location.pathname },
        { property: 'og:image', content: absoluteURL + '/images/logo.jpg' },
        { name: 'robots', content: 'index,follow' }
      ],
      link: [
        { rel: 'canonical', href: absoluteURL + window.location.pathname }
      ]
    }
  }
}
</script>

<style lang="scss">
  #external-warning {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($lightGreen, 0.85);
    align-items: center;
    justify-content: center;
    z-index: 9999999;

    .text {
      text-align: center;
      color: $white;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      max-width: 450px;
      padding: 20px;
      text-shadow: 0 0 5px $darkGreen;

      span {
        font-style: italic;
      }

      a {
        color: $white;
        font-size: 14px;
        line-height: normal;
        text-decoration: none;

        &:hover {
          text-shadow: none;
          color: $darkGreen;
        }
      }
    }
  }
</style>
