<template>
  <div class="map">
    <svg xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" id="map" width="1024" height="960" viewBox="200 65 775 630" preserveAspectRatio="xMinYMin">
      <map-country v-for="country in countries" :key="country.code" :country="country"></map-country>
    </svg>
  </div>
</template>

<script>
import Country from './Country'

export default {
  computed: {
    countries () {
      return this.$store.state.countries
    }
  },
  components: {
    mapCountry: Country
  }
}
</script>

<style lang="scss" scoped>
  .map {
    position: relative;
    max-width: 100%;
    overflow: auto;
    @include scrollbars($background, $lightBlue);
  }

  svg {
    border: 0 none;
  }
</style>
