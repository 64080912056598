<template>
  <div class="content">
    <h1>Patient story</h1>
    <h2 class="light-green">Cristina, Romania</h2>
    <p><img class="left" srcset="/images/pages/cristina-1@2x.png 2x, /images/pages/cristina-1.png 1x" src="/images/pages/cristina-1.png" alt="Patient story, Cristina" /><span class="bold">Cristina’s life changed dramatically when she was diagnosed with intestinal failure (IF)</span><br />
        Cristina developed complications following surgery for a uterine leiomyoma, which led to her developing SBS. The specialist care she needed was not available in Romania, meaning she was transferred over 1,000 miles away to France for further treatment in 2017.  In order to receive the nutrients her body needs to survive, like many other patients in her position, Cristina spends 13 hours connected intravenously to parenteral nutrition (PN).</p>
    <br />
    <p><span class="bold">Just to survive, Cristina must live miles away from her family and friends</span><br />
      The Romanian health service does not provide access to home parenteral nutrition (HPN) nor the specialist training to doctors and nurses to administer it, meaning Cristina was forced to make the life-changing decision of permanently moving from her home in order to survive.</p>
    <p>Cristina found the move stressful and difficult to cope with.</p>
    <p class="italic light-blue">“My move to France was life-saving but at the same time it came with trauma. The physical ones are obvious from the medical documents, but I can say that I felt and still feel psychological disorders generated by the lack of family and friends, as well as related to the difficulty of adapting to a country and foreign culture, especially in my condition (low mobility, chronic fatigue, foreign language, administrative difficulties, etc.).”</p>
    <p><img class="right" srcset="/images/pages/cristina-2@2x.png 2x, /images/pages/cristina-2.png 1x" src="/images/pages/cristina-2.png" alt="Patient story, Cristina" />Cristina has lived in France for since 2017, approximately 80km from Beaujon Hospital where she is still cared for. She has been able to visit her family and friends in Romania just three times since her arrival in France, travelling with all the necessary medical equipment and self-medicating at her own risk. Cristina says: <span class="italic light-blue">“For me, it is obvious that I am not returning to Romania for good.”</span></p>

    <p><span class="bold">Living with HPN</span><br />
    HPN has kept Cristina alive, although living with the constraints of HPN has been extremely difficult. Under the guidance of her physician, Cristina attempted to wean herself off the daily infusions to a less intense regimen, but found this to be a step too far: <span class="italic light-blue">“I couldn’t rest at night, I couldn’t get out of the house much, I couldn’t take care of the small things in the household.”</span></p>

    <p><span class="bold">Cristina’s hopes for the future</span><br />
      Cristina hopes that, through the efforts of ATLAS, conditions for intestinal failure patients will improve and that parenteral nutrition programs are made more widely available.</p>
    <br />
    <atlas-quote :text="'“I hope that this project will have the desired effect at the European level so that all Member States can make adapted parenteral nutrition programmes available to their citizens. It would help many people, myself included, if I would like to visit my home, or simply go somewhere on vacation.”'"></atlas-quote>
  </div>
</template>

<script>
import AtlasQuote from '@/components/Quote'

export default {
  data () {
    return {
      title: 'Patient case study',
      description: 'Cristina\'s life changed dramatically when she was diagnosed with intestinal failure (IF). Read her story as she highlights the need for change in treating IF.',
      keywords: 'Intestinal Failure, Patients, Patient Stories, Constipation, Colon'
    }
  },
  components: {
    AtlasQuote
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>

<style lang="scss">
  .content {
    img.left {
      float: left;
      margin-right: 15px;
      margin-bottom: 15px;
    }

    img.right {
      float: right;
      margin-left: 15px;
      margin-bottom: 15px;
    }
  }
</style>
