import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Vision from '../views/WhatIsAtlas/Vision'
import History from '../views/WhatIsAtlas/History'
import Events from '../views/WhatIsAtlas/Events'
import Committee from '../views/WhatIsAtlas/Committee'
import Definition from '../views/WhatIsIF/Definition'
import Needs from '../views/WhatIsIF/Needs'
import Lack from '../views/WhatIsIF/Lack'
import Map from '../views/StandardOfCare/Map'
import OverviewEurope from '../views/StandardOfCare/OverviewEurope'
import CaseStudy from '../views/StandardOfCare/CaseStudy'
import Dashboards from '../views/Resources/Dashboards'
import Policy from '../views/Resources/Policy'
// import Literature from '../views/Resources/Literature'
import Infographics from '../views/Resources/Infographics'
import ReferenceCentres from '../views/Resources/ReferenceCentres'
import ComparisonMaps from '../views/Resources/ComparisonMaps'
import About from '../views/About'
import Contact from '../views/Contact'
import Cookies from '../views/Cookies'
import PageNotFound from '../views/PageNotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-is-atlas/vision-mission-and-approach/',
    name: 'Vision',
    component: Vision,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-is-atlas/history-of-atlas/',
    name: 'History',
    component: History,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-is-atlas/steering-committee/',
    name: 'Committee',
    component: Committee,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-is-atlas/events/',
    name: 'Events',
    component: Events,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-is-if/definition/',
    name: 'Definition',
    component: Definition,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-is-if/unmet-needs/',
    name: 'Needs',
    component: Needs,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/what-is-if/lack-of-icd-code-for-sbs-and-if/',
    name: 'Lack',
    component: Lack,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/standard-of-care/if-treatment-and-care-across-europe/',
    name: 'Map',
    component: Map,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/standard-of-care/overview-of-europe/',
    name: 'OverviewEurope',
    component: OverviewEurope,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/standard-of-care/patient-case-study/',
    name: 'CaseStudy',
    component: CaseStudy,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/resources/dashboards/',
    name: 'Dashboards',
    component: Dashboards,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/resources/policy-paper/',
    name: 'Policy',
    component: Policy,
    pathToRegexpOptions: { strict: true }
  },
  // {
  //   path: '/resources/literature-review',
  //   name: 'Literature',
  //   component: Literature,
  //   pathToRegexpOptions: { strict: true }
  // },
  {
    path: '/resources/infographics/',
    name: 'Infographic',
    component: Infographics,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/resources/reference-centres/',
    name: 'ReferenceCentres',
    component: ReferenceCentres,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/resources/if-vs-other-organ-failures/',
    name: 'ComparisonMaps',
    component: ComparisonMaps,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/about-takeda/',
    name: 'About',
    component: About,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/contact-us/',
    name: 'Contact',
    component: Contact,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '/cookies/',
    name: 'Cookies',
    component: Cookies,
    pathToRegexpOptions: { strict: true }
  },
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

export default router
