<template>
    <div class="column">
      <div class="value">{{ data.value === null ? '?' : data.value }}</div>
      <div class="unit" v-if="unit.length > 0">{{ data.value !== null ? unit : '' }}</div>
      <div class="label"><slot></slot> <reference :reference="data.reference"></reference></div>
    </div>
</template>

<script>
import Reference from './Reference'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    unit: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    reference: Reference
  }
}
</script>

<style lang="scss" scoped>
  .column {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 40%;
    flex-grow: 1;
    flex-basis: 0;

    + .column {
      margin-left: 5px;
    }

    .value {
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      color: $lightGreen;
    }

    .unit {
      font-size: 11px;
      color: $lightBlue;
    }

    .label {
      margin-top: 5px;

      .smaller {
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
</style>
