<template>
  <div :class="{ 'need': true, collapsed: collapsed }">
    <div class="image">
      <img :srcset="'/images/pages/needs/' + id + '@2x.png 2x,/images/pages/needs/' + id + '.png 1x'" :src="'/images/pages/needs/' + id + '.png'" :alt="'Unmet need ' + id" />
      <div class="toggle" @click="collapsed = !collapsed"></div>
    </div>
    <div class="text">
      <h2>Unmet need {{ id }}</h2>
      <p class="bold light-green">{{ title }}<sup>1</sup></p>
      <div class="teaser toggle" @click="collapsed = !collapsed"></div>
      <div class="body-wrapper">
        <div class="body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      collapsed: true
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .need {
    background: $white;
    border-radius: 24px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-bottom: 30px;

    .text {
      flex-grow: 1;
      padding: 30px 30px 0 30px;
      position: relative;
    }

    .toggle {
      position: absolute;
      bottom: 30px;
      -webkit-mask: url('/images/menu_close.svg') no-repeat;
      mask: url('/images/menu_close.svg') no-repeat;
      background: $darkBlue;
      width: 36px;
      height: 36px;
      transition-duration: 0.3s;
      transition-property: transform;
      cursor: pointer;
    }

    .image {
      align-self: flex-end;
      width: 100%;

      img {
        margin: 0;
        height: 100%;
        max-width: inherit;
      }
    }

    .teaser {
      display: none;
    }

    .body-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:after {
        content: '';
        height: 1px;
        transition: height 0.5s linear, max-height 0s 0.5s linear;
        max-height: 0;
      }

      .body {
        transition: margin-bottom 0.5s cubic-bezier(0, 0, 0, 1);
        margin-left: 0;
        margin-bottom: 0;
        max-height: 1000000px;
      }
    }

    &:not(.collapsed) .image {
      margin-top: -20px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      .toggle {
        position: relative;
        bottom: inherit;
        align-self: flex-end;
        margin: 30px;
      }
    }

    &.collapsed {
      flex-direction: row-reverse;

      .toggle {
        transform: rotate(45deg);
      }

      .image {
        .toggle {
          display: none;
        }
      }

      .teaser {
        display: block;
      }

      .body-wrapper {
        &:after {
          height: 0;
          transition: height 0.5s linear;
          max-height: 1px;
        }

        .body {
          margin-bottom: -2000px;
          transition: margin-bottom 0.5s cubic-bezier(1, 0, 1, 1),
                      visibility 0s 0.5s,
                      max-height 0s 0.5s;
          visibility: hidden;
          max-height: 0;
        }
      }
    }

    &:nth-child(2n) {
      .teaser.toggle {
        right: 30px;
      }

      &.collapsed {
        flex-direction: row;
      }

      &:not(.collapsed) .image {
        flex-direction: row;
      }
    }
  }
</style>
