<template>
  <div>
    <h1>{{ title }}</h1>
    <span class="meta">Data last updated: April 2020, clinical trials data updated: August 2020</span>
    <div class="not-optimal message message-info">
      <div class="icon icon-warning"></div>
      <p>The interactive map is best viewed on a desktop screen. On smaller screens the experience might not be optimal.</p>
    </div>
    <div class="map-wrapper">
      <european-map></european-map>
      <country-details v-if="selectedCountry !== false" :country="countries[selectedCountry]"></country-details>
    </div>
    <div class="message message-info light-green">
      <p>You can download this data and information in the form of <router-link :to="{ 'name': 'Dashboards' }">Country Dashboards</router-link>.</p>
    </div>
  </div>
</template>

<script>
import Map from '@/components/european-map/Map'
import CountryDetails from '@/components/country-details/CountryDetails'

export default {
  data () {
    return {
      title: 'IF treatment and care across Europe',
      description: 'A standardised benchmark about intestinal failure (IF) treatment and care landscape in an interactive map.',
      keywords: 'Intestinal Failure, Standard of Care, Treatment, Inflammatory Bowel Disease, Crohn’s Disease, Irritable bowel syndrome'
    }
  },
  computed: {
    countries () {
      return this.$store.state.countries
    },
    selectedCountry () {
      return this.$store.state.selectedCountry
    }
  },
  components: {
    europeanMap: Map,
    countryDetails: CountryDetails
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>

<style lang="scss">
  .map-wrapper {
    position: relative;
    max-width: 100%;
  }

  .meta {
    margin-top: -46px;
    display: block;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: $grey;
  }

  @media screen and (min-width: $maxWidth) {
    .message.not-optimal {
      display: none;
    }
  }
</style>
