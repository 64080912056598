export default {
  pop: {
    value: '9,982,703',
    reference: {
      text: 'The Global Cancer Observatory (2018), Sweden, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/752-sweden-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '61.271',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 83.69,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 15.03,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '11,234',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '13,469',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: 1,
    reference: {
      text: 'Sahlgrenska International Care (2020), Intestinal Failure Treatment, Sahlgrenska International Care, viewed 21 August 2020',
      link: 'http://www.sahlgrenskaic.com/medical-care/treatments/intestinal-failure-treatment/'
    }
  },
  research_liver: {
    value: 3,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 62,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Hem Parenteral Nutrition (HPN)',
      type: '<abbr title="Home Artificial Nutrition">HAN</abbr>, <abbr title="Gastrointestinal">GI</abbr>',
      reference: {
        text: 'Svenska HPN-Föreningen. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.hpn.se'
      }
    },
    {
      name: 'ILCO',
      type: '<abbr title="Gastrointestinal">GI</abbr>',
      reference: {
        text: 'ILCO. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.ilco.nu'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'Tandvårds- och läkemedelsförmånsverket, The Swedish Dental and Pharmaceutical Benefits Agency, 2019. Nutriflex Omega ingår i högkostnadsskyddet. Viewed 25 January 2021.',
      link: 'https://www.tlv.se/beslut/beslut-lakemedel/generell-subvention/arkiv/2019-06-12-nutriflex-omega-ingar-i-hogkostnadsskyddet.html'
    }
  },
  nat_guidelines_cif: {
    value: true,
    reference: {
      text: 'ELLEGÅRD, L. et al. (2016), Tarmsvikt – Handläggning och behandling, Svensk Gastroenterologisk Förening, viewed 21 August 2020',
      link: 'https://svenskgastroenterologi.se/wp-content/uploads/2017/06/2016-Tarmsvikt.pdf'
    }
  },
  nat_guidelines_sbs: {
    value: true,
    reference: {
      text: 'ELLEGÅRD, L. et al. (2016), Tarmsvikt – Handläggning och behandling, Svensk Gastroenterologisk Förening, viewed 21 August 2020',
      link: 'https://svenskgastroenterologi.se/wp-content/uploads/2017/06/2016-Tarmsvikt.pdf'
    }
  }
}
