<template>
  <div class="country-popup">
    <img src="/images/close.svg" class="close" alt="Close details" @click="closePopup" />
    <div class="section header">
      <div class="icon-block">
        <img v-bind:src="'/images/flags/' + country.code + '.svg'" class="flag" :alt="country.name" />
      </div>
      <div class="data">
        <h2>{{ country.name }}</h2>
        <h3>Population: {{ country.data.pop.value }} <reference :reference="country.data.pop.reference"></reference></h3>
      </div>
    </div>
    <div class="scrollable">
      <div class="section">
        <div class="icon-block">
          <img src="/images/country_context.svg" alt="Country Context" />
          <div class="title">Country Context</div>
        </div>
        <div class="data">
          <div class="row">
            <div class="label bill" style="margin-right: 10px">Health expenditure</div>
            <div class="value bold nowrap">US$ {{ country.data.health_exp.value }} bn <reference :reference="country.data.health_exp.reference"></reference></div>
          </div>
          <div class="graph">
            <div class="gov" :style="'width: ' + country.data.gov_exp.value + '%'"></div>
            <div :class="{'oop': true, 'solo': country.data.gov_exp.value <= 0}" :style="'width: calc(' + country.data.oop_exp.value + '% + 8px)'">
              <div class="oop-inner">
                <i></i>
                <div class="bar"></div>
              </div>
            </div>
          </div>
          <div class="legend">
            <div class="circle gov nowrap">Government <reference :reference="country.data.gov_exp.reference"></reference></div>
            <div class="circle oop nowrap">Out-of-pocket <reference :reference="country.data.oop_exp.reference"></reference></div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="icon-block" style="padding-right: 10px">
          <img src="/images/disease_burden_epidemiology.svg" alt="Disease Burden/Epidemiology" />
          <div class="title">Disease Burden/ Epidemiology</div>
        </div>
        <div class="data">
          <div class="columns">
            <number-column :data="country.data.cif_sbs_if" :unit="'/million (HPN)'">
              <abbr title="Chronic Intestinal Failure">CIF</abbr>/<abbr title="Short Bowel Syndrome with Intestinal Failure">SBS</abbr><div class="smaller">prevalence (rate)</div>
            </number-column>
            <number-column :data="country.data.kidney" :unit="'/100,000'">
              CHRONIC KIDNEY DISEASE<div class="smaller">prevalence (rate)</div>
            </number-column>
            <number-column :data="country.data.liver" :unit="'/100,000'">
              CHRONIC LIVER DISEASE<div class="smaller">prevalence (rate)</div>
            </number-column>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="icon-block">
          <img src="/images/registries.svg" alt="Registries" />
          <div class="title">Registries</div>
        </div>
        <div class="data">
          <h4>Registry in the country</h4>
          <div class="columns">
            <checkbox-column :data="country.data.reg_cif"><abbr title="Chronic Intestinal Failure">CIF</abbr></checkbox-column>
            <checkbox-column :data="country.data.reg_sbs_if"><abbr title="Short Bowel Syndrome with Intestinal Failure">SBS</abbr></checkbox-column>
            <checkbox-column :data="country.data.reg_hpn"><abbr title="Home Parenteral Nutrition">HPN</abbr></checkbox-column>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="icon-block">
          <img src="/images/reference_centers.svg" alt="Reference centers" />
          <div class="title">Reference centers</div>
        </div>
        <div class="data vcenter">
          <div class="row fixed-width">
            <div class="label">Reference centres/<br />centres of excellence <reference :reference="country.data.ref_centers.reference"></reference></div>
            <div class="value bold light-green big center">{{ country.data.ref_centers.value !== null ? country.data.ref_centers.value : '?' }}</div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="icon-block">
          <img src="/images/research.svg" alt="Research" />
          <div class="title">Research</div>
        </div>
        <div class="data">
          <h4>Clinical trials from 12/08/10 to 12/08/20</h4>
          <div class="columns">
            <number-column :data="country.data.research_liver">Liver failure</number-column>
            <number-column :data="country.data.research_renal">Renal failure</number-column>
            <number-column :data="country.data.research_intestinal">Intestinal failure <reference :reference="{
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Intestinal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1209&map.y=382&recrs=adef&cond=intestinal+failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }"></reference> and short bowel syndrome <reference :reference="{
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Short Bowel Syndrome, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1199&map.y=387&recrs=adef&cond=Short+Bowel+Syndrome&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }"></reference></number-column>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="icon-block">
          <img src="/images/hpn.svg" alt="HPN Availability & Reimbursement" />
          <div class="title"><abbr title="Home Parenteral Nutrition">HPN</abbr><br />Availability & Reimbursement</div>
        </div>
        <div class="data vcenter">
          <div class="columns columns-2">
            <checkbox-column :data="country.data.hpn_available">Available in the country?</checkbox-column>
            <checkbox-column :data="country.data.hpn_reimbursed">Reimbursed in the country?</checkbox-column>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="icon-block">
          <img src="/images/national_guidelines.svg" alt="National Guidelines" />
          <div class="title">National Guidelines</div>
        </div>
        <div class="data vcenter">
          <div class="columns columns-2">
            <checkbox-column :data="country.data.nat_guidelines_cif"><abbr title="Chronic Intestinal Failure">CIF</abbr></checkbox-column>
            <checkbox-column :data="country.data.nat_guidelines_sbs"><abbr title="Short Bowel Syndrome">SBS</abbr></checkbox-column>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="icon-block">
          <img src="/images/patient_organisations.svg" alt="Patient Organisations" />
          <div class="title">Patient Organisations</div>
        </div>
        <div class="data" style="padding-left: 10px">
          <div v-for="(patient, index) in country.data.patient_orgs" :key="index" class="patient-organisation">
            <div class="code" v-html="patient.type"></div>
            <div class="name">{{ patient.name }} <reference :reference="patient.reference"></reference></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Reference from './Reference'
import NumberColumn from './NumberColumn'
import CheckboxColumn from './CheckboxColumn'

export default {
  props: {
    country: {
      type: Object,
      required: true
    }
  },
  methods: {
    closePopup () {
      this.$store.dispatch('deselectCountry')
    }
  },
  components: {
    reference: Reference,
    numberColumn: NumberColumn,
    checkboxColumn: CheckboxColumn
  }
}
</script>

<style lang="scss" scoped>
  .big {
    font-size: 25px;
    line-height: 30px;
  }

  .nowrap {
    white-space: nowrap;
  }

  .center {
    align-self: center;
    text-align: center;
  }

  .country-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    width: 360px;
    max-height: calc(100% - 20px);
    overflow-y: hidden;
    background: $darkBlue;
    border-radius: 50px 0;
    color: $white;
    padding: 10px;
    font-family: "Arial Narrow", $font;
    font-size: 16px;
    line-height: 19px;
    outline: none;

    .scrollable {
      max-height: 835px;
      overflow-y: auto;
      @include scrollbars($darkBlue, $lightBlue);
    }

    .close {
      width: 20px;
      height: 20px;
      float: right;
      display: block;
      cursor: pointer;
      z-index: 110;
    }

    .section {
      display: flex;
      padding: 10px 5px 20px;
    }

    .section:not(.header) {
      border-top: 1px solid $white;

      .icon-block {
        padding-top: 10px;
      }
    }

    .icon-block {
      width: 95px;
      text-align: center;

      img {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-bottom: 10px;

        &.flag {
          width: 49px;
          height: 49px;
          border-radius: 100%;
          border: 2px solid $white;
          margin: 0;
        }
      }

      .title {
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
      }
    }

    .data {
      width: calc(100% - 95px);

      &.vcenter {
        display: flex;
        align-items: center;

        > div {
          flex-grow: 1;

          &.row {
            padding-top: 10px;
          }
        }
      }
    }

    h2 {
      font-size: 25px;
      line-height: 30px;
      color: $white;
    }

    h3 {
      font-size: 15px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .graph {
      margin: 20px 0 10px;
      background: $white;
      border-radius: 20px;
      width: 100%;
      height: 20px;
      z-index: 100;

      > div {
        height: 100%;
        display: inline-flex;
      }

      .gov {
        background: $lightGreen;
        border-radius: 20px;
        z-index: 103;
      }

      .oop {
        height: 100%;
        overflow: hidden;
        margin-left: -8px;

        .oop-inner {
          height: 100%;
          width: 100%;
        }

        i {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-color: $lightGreen;
          float: left;
          margin-left: -10px;
        }

        .bar {
          background: $lightBlue;
          z-index: 101;
          width: 100%;
          height: 100%;
          border-radius: 0 20px 20px 0;
        }

        &.solo {
          margin-left: 0;
          border-radius: 20px;

          i {
            display: none;
          }

          .bar {
            width: calc(100% - 8px);
          }
        }
      }
    }

    .legend {
      display: flex;
      font-size: 14px;

      .circle {
        display: inline-block;

        &:before {
          content: " ";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 100%;
          vertical-align: middle;
          margin-right: 3px;
        }

        &.gov:before {
          background: $lightGreen;
        }

        &.oop:before {
          background: $lightBlue;
        }

        + .circle {
          margin-left: 15px;
        }
      }
    }

    .row {
      display: flex;
      justify-content: space-between;

      &.fixed-width {
        .label {
          width: 75%;
        }

        .value {
          width: 25%;
        }
      }

      .bill {
        width: 55%;
      }
    }

    .columns {
      display: flex;

      &.columns-2 {
        .column {
          max-width: inherit;
        }
      }
    }

    .patient-organisation {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      line-height: 16px;

      .code {
        width: 33%;
        color: $lightGreen;
      }

      .name {
        width: 66%;
      }

      + .patient-organisation {
        margin-top: 15px;
      }
    }
  }

  @media screen and (max-width: $maxWidth - 1px) {
    .country-popup {
      top: 0;
      right: 0;
    }
  }
</style>
