<template>
    <div class="column">
      <div :class="{circle: true, yes: data.value === true, 'no-data': data.value === null, no: data.value === false}"><reference class="reference" :reference="data.reference"></reference></div>
      <div :class="{label: true, yes: data.value === true, 'no-data': data.value === null, no: data.value === false}"><slot></slot></div>
    </div>
</template>

<script>
import Reference from './Reference'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    reference: Reference
  }
}
</script>

<style lang="scss" scoped>
  .column {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 40%;
    flex-grow: 1;
    flex-basis: 0;

    .circle {
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      margin: 5px auto 5px;
      border: 2px solid $white;

      &.yes {
        background: $lightGreen;
      }

      &.no-data {
        background: transparent;
      }

      &.no {
        background: $red;
      }
    }

    .reference {
      display: inline-block;
      position: absolute;
      right: -17px;
      top: -8px;
    }

    .label {
      &.no-data {
        color: $lightBlue;
      }
    }
  }
</style>
