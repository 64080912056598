<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>Cookies are temporary text files that your computer, tablet or smartphone saves when you visit our website. Thanks to the information in these cookies we can gain insights in how this website is being used and what we could improve your visiting experience. The website uses the following cookies:</p>
    <table>
      <thead>
        <tr>
          <th>Cookie name</th>
          <th>Expiration time</th>
          <th>Placed by</th>
          <th>Purpose</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>_ga<br />_ga_*</td>
          <td>2 years</td>
          <td>Google</td>
          <td>Through these cookies we analyze activities on our website so that we can improve your visiting experience. All information is anonymized so that you cannot be identified. We share this information with Google.</td>
        </tr>
      </tbody>
    </table>
    <p>By using this site you agree to our use of cookies as set out in our privacy notice. <a href="https://www.takeda.com/privacy-notice/" target="_blank" rel="nofollow">Please read our privacy notice</a> for more information on the cookies we use and how to delete or block the use of cookies.</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: 'Cookie policy',
      description: '',
      keywords: ''
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  table {
    width: 100%;
    border: 0 none;
    border-collapse: collapse;
    margin-bottom: 25px;

    thead {
      background: $white;
    }

    th {
      padding: 15px;
      border: 1px solid $lightGrey;
    }

    td{
      padding: 10px 15px;
      vertical-align: top;
      border: 1px solid $lightGrey;
    }
  }

  @media screen and (min-width: 600px) {
    table {
      thead {
        white-space: nowrap;
      }
    }
  }
</style>
