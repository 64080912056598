export default {
  pop: {
    value: '11,498,527',
    reference: {
      text: 'The Global Cancer Observatory (2018), Belgium, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/56-belgium-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '34.763',
    reference: {
      text: 'Institut national d’assurance maladie-invalidité (INAMI) (2019), Le budget 2020 de l’assurance soins de santé est approuvé, Institut national d’assurance maladie-invalidité (INAMI), viewed 21 August 2020',
      link: 'https://www.inami.fgov.be/fr/nouvelles/Pages/budget-soins-sante-20191122.aspx'
    }
  },
  gov_exp: {
    value: 77.22,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 17.64,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: 14,
    reference: {
      text: 'UMBRAIN, N. et al. (2019). Impact of nutrition support team (NST) funding from the Belgian National Health Insurance (NHI) on the quality of care of home parenteral nutrition (HPN) in adults with benign diseases. Clinical Nutrition. 38. S59-S296',
      link: null
    }
  },
  kidney: {
    value: '9,735',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbak fuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '12,856',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbak fuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: true,
    reference: {
      text: 'UMBRAIN, N. et al. (2019). Impact of nutrition support team (NST) funding from the Belgian National Health Insurance (NHI) on the quality of care of home parenteral nutrition (HPN) in adults with benign diseases. Clinical Nutrition. 38. S59-S296',
      link: null
    }
  },
  ref_centers: {
    value: 6,
    reference: {
      text: 'UMBRAIN, N. et al. (2019). Impact of nutrition support team (NST) funding from the Belgian National Health Insurance (NHI) on the quality of care of home parenteral nutrition (HPN) in adults with benign diseases. Clinical Nutrition. 38. S59-S296',
      link: null
    }
  },
  research_liver: {
    value: 12,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 73,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 5,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Hello TPN',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>',
      reference: {
        text: 'Hello TPN. 2019. Homepage. Viewed 21 August 2020',
        link: 'https://www.vzwhellotpn.be'
      }
    },
    {
      name: 'La vie par un Fil Belgique asbl',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>',
      reference: {
        text: 'La Vie par un Fil. 2020. Homepage. Viewed 21 August 2020',
        link: 'http://www.lavieparunfilbelgique.be/index.php'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'MISTIAEN, P. et al. (2019). Organization and reimbursement of enteral and parenteral nutrition in and outside of the hospital in Belgium. Belgian Healthcare Knowledge Centre (KCE). Report 315',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: false,
    reference: {
      text: 'BAXTER, JP. et al. (2012). Home parenteral nutrition: An international benchmarking exercise. Clinical Nutrition. 7(5). E211-214',
      link: null
    }
  },
  nat_guidelines_sbs: {
    value: false,
    reference: {
      text: 'BAXTER, JP. et al. (2012). Home parenteral nutrition: An international benchmarking exercise. Clinical Nutrition. 7(5). E211-214',
      link: null
    }
  }
}
