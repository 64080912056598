import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: {
    id: 'G-DW98JFGK1F',
    params: {
      anonymize_ip: true
    }
  },
  enabled: window.location.hostname.match(/^(www\.)?atlasif\.eu$/i) !== null
}, router)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
