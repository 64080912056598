<template>
  <div class="content">
    <h1>{{ title }}</h1>

    <h2>The importance of an ICD code</h2>
    <p>Despite being a highly debilitating condition, which affects people of all ages, intestinal failure (IF) is largely unrecognised.<sup>1</sup></p>
    <ul>
      <li>Without an International Classification of Disease (ICD) code for IF and short  bowel syndrome (SBS), it is difficult to generate the outcomes and epidemiology data that are vital to the maintenance of best-practice medical guidance.</li>
      <li>As a result, IF has yet to receive the public attention, policy focus or research funding that other organ failures such as kidney and liver failure receive.</li>
    </ul>

    <h2>Application status</h2>
    <p>There is presently an application underway with the World Health Organisation (WHO) to secure an ICD code for IF in adults as well as SBS.</p>
    <p>The WHO has progressed the application for an ICD code for SBS and IF to the next step, but it is still not in use.</p>
    <p>This is huge progress for the IF community and will enable our patients to gain the right diagnosis, treatment and care they deserve. The ability to accurately code SBS and IF for both adult and paediatric patients will lead to easier reimbursement as well as improved insights into this ultra-rare patient population.</p>
    <p>Creating this type of change is a long and complex process. It was first started by the <router-link :to="{name: 'Committee'}">ATLAS Steering Committee</router-link> and other Key Opinion Leaders (KOLs) in the field of IF, in Autumn 2018 and is a key objective of the group. It also takes more than just one KOL creating a proposal but requires the long-term support and efforts of other KOLs and healthcare professionals (HCPs), along with patient groups to drive change.</p>
    <p>Monitor the progress of this process on the <a href="https://icd.who.int/dev11/proposals/f/en" target="_blank" @click="externalURL" rel="nofollow">WHO petition website</a>.</p>

    <atlas-support></atlas-support>
    <atlas-references>
      <ol>
        <li>ICD. (2017). International Classification of Diseases (ICD). ICD-10 Version. Viewed 30 April 2020. <a href="http://apps.who.int/classifications/icd10/browse/2010/en#/XI" target="_blank" @click="externalURL" rel="nofollow">http://apps.who.int/classifications/icd10/browse/2010/en#/XI</a>.</li>
      </ol>
    </atlas-references>
  </div>
</template>

<script>
import AtlasSupport from '@/components/Support'
import AtlasReferences from '@/components/References'
import externalURL from '@/external-url'

export default {
  data () {
    return {
      title: 'Lack of ICD code for SBS and IF',
      description: 'What is intestinal failure? Despite being a highly debilitating condition, which affects people of all ages, intestinal failure is largely unrecognized.',
      keywords: 'ICD Code, Short bowel syndrome, Intestinal Failure, crohn\'s disease'
    }
  },
  methods: {
    externalURL
  },
  components: {
    AtlasSupport,
    AtlasReferences
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
