<template>
  <div v-if="reference !== null" class="reference-container" :style="'width:' + width">
    <div v-if="reference.link !== null">
      <a :href="reference.link" target="_blank" @click="externalURL" rel="nofollow" :title="reference.text">
        <img src="/images/info.svg" alt="References" :title="reference.text" />
      </a>
    </div>
    <div v-else>
      <img src="/images/info.svg" alt="References" :title="reference.text" />
    </div>
  </div>
</template>

<script>
import externalURL from '@/external-url'

export default {
  props: {
    reference: {
      type: Object,
      required: false,
      default: null
    },
    width: {
      type: String,
      required: false,
      default: '12px'
    }
  },
  methods: {
    externalURL
  }
}
</script>

<style lang="scss" scoped>
  .reference-container {
    margin-right: 2px;
  }

  div,
  a {
    width: 100%;
    display: inline-block;
  }

  a {
    margin: 1px 2px 0 2px;
    border-radius: 100%;
    height: 100%;
    vertical-align: top;
  }

  .icon {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
</style>
