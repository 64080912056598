export default {
  pop: {
    value: '46,397,446',
    reference: {
      text: 'The Global Cancer Observatory (2018), Spain, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/724-spain-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '125.87',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 70.62,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 23.57,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '5.1',
    reference: {
      text: 'KELLY, DG. et al. (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.',
      link: null
    }
  },
  kidney: {
    value: '9,126',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '17,898',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: true,
    reference: {
      text: 'Spanish Rare Diseases Registries Research Network Instituto de Salud Carlos III, viewed 21 August 2020',
      link: 'https://spainrdr.isciii.es/es/Paginas/default.aspx'
    }
  },
  reg_hpn: {
    value: true,
    reference: {
      text: 'WANDEN-BERGHE, C. et al. (2018). Home and Ambulatory Artificial Nutrition (NADYA) Report. Home Parenteral Nutrition in Spain. Clinical Nutrition. 35(6). 1491-1496.',
      link: null
    }
  },
  ref_centers: {
    value: 6,
    reference: {
      text: 'STAUN, M. et al. (2007). Management of intestinal failure in Europe. A questionnaire-based study on the incidence and management. Dynamic Medicine. 6(1).',
      link: null
    }
  },
  research_liver: {
    value: 11,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 112,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [
    {
      name: 'NUPA',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>, <abbr title="Intravenous">IV</abbr>',
      reference: {
        text: 'Asociación de niños, adultos y familias con fallo intestinal, nutrición parenteral y trasplante múltiple. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://somosnupa.org'
      }
    },
    {
      name: 'Asociación valenciana de niños con síndrome de intestino corto y dependientes de nutrición parenteral (ASVANIC)',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Asociación valenciana de niños con síndrome de intestino corto y dependientes de nutrición parenteral. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.solucionesong.org/ong/asociacion-valenciana-de-ninos-con-sindrome-de-intestino-corto-y-dependientes-de-nutricion-parenteral-asvanic/10160/view'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'DREESEN, M. et al. (2013). Development of quality of care interventions for adult patients on home parenteral nutrition (HPN) with a benign underlying disease using a two-round Delphi approach. Clinical Nutrition. 32(1). 59-64.',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: true,
    reference: {
      text: 'SEGHNP Sociedad Española de Gastroenterología Pediatrica, viewed 21 August 2020',
      link: 'https://www.seghnp.org/documentos?field_tipo_de_documento_target_id_1=11'
    }
  },
  nat_guidelines_sbs: {
    value: true,
    reference: {
      text: 'SEGHNP Sociedad Española de Gastroenterología Pediatrica, viewed 21 August 2020',
      link: 'https://www.seghnp.org/documentos?field_tipo_de_documento_target_id_1=11'
    }
  }
}
