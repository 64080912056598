<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>The ATLAS Country Dashboards aim to create a standardised benchmark platform from which to measure, monitor and talk about the intestinal failure (IF) treatment and care landscape.</p>

    <p>They encompass 24 countries across Europe and give information about a range of areas, including:</p>

    <ul>
      <li>Country contexts (inc. populations and health expenditures)</li>
      <li>Disease burden and epidemiology for IF, chronic kidney disease and chronic liver disease</li>
      <li>Registries for IF, short bowel syndrome (SBS) and home parenteral nutrition (HPN)</li>
      <li>National Reference Centres</li>
      <li>Clinical trials research</li>
      <li>Patient organisations</li>
      <li>HPN availability and reimbursement</li>
      <li>National guidelines for IF and SBS</li>
    </ul>

    <p>Data collection and validation took place from October 2019 to August 2020.</p>

    <p>Sources are publicly available and include peer-reviewed academic sources, official Governmental or national/regional authority sources, and reputable online sources.</p>

    <p>Some of the data points may change over time in the original public source (for example, population), and therefore an audit trail is available on request providing evidence of the data point value at the time of access.</p>

    <p>There is a significant lack of local IF data in some European countries, so not all countries are represented in the Dashboards. We have only been able to reference publicly available data and have not included any opinions. This is a limitation of the data provided.</p>

    <p>Once we have seen progress against our objectives, we may consider expanding the reach of the programme beyond these 24 countries and/or to other regions.</p>
    <a href="/files/dashboards.pdf" class="download" target="_blank" title="Download the country dashboards">
      <img srcset="/images/pages/dashboards@2x.jpg 2x, /images/pages/dashboards.jpg 1x" src="/images/pages/dashboards.jpg" :alt="title" />
      <span>Download as PDF</span>
    </a>
    <atlas-support></atlas-support>
  </div>
</template>

<script>
import AtlasSupport from '@/components/Support'

export default {
  data () {
    return {
      title: 'Dashboards',
      description: 'ATLAS Country Dashboards can create a standardised benchmark about the intestinal failure treatment and new methods of patient care landscape.',
      keywords: 'Intestinal Failure, IBS Treatment, Patient Care'
    }
  },
  components: {
    AtlasSupport
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
