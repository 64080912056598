import Vue from 'vue'
import Vuex from 'vuex'
import countries from '../data/countries.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedMember: null,
    countries: countries,
    selectedCountry: false,
    externalWarning: null
  },
  mutations: {
    selectCountry (state, country) {
      if (state.selectedCountry !== false) {
        state.countries[state.selectedCountry].selected = false
      }
      state.selectedCountry = country
      state.countries[country].selected = true
    },
    deselectCountry (state) {
      state.countries[state.selectedCountry].selected = false
      state.selectedCountry = false
    },
    selectMember (state, id) {
      state.selectedMember = id
    },
    setExternalWarning (state, value) {
      state.externalWarning = value
    }
  },
  actions: {
    selectCountry ({ commit }, country) {
      commit('selectCountry', country)
    },
    deselectCountry ({ commit }, country) {
      commit('deselectCountry', country)
    },
    selectMember ({ commit }, id) {
      commit('selectMember', id)
    },
    setExternalWarning ({ commit }, value) {
      commit('setExternalWarning', value)
    }
  },
  modules: {
  }
})
