<template>
  <div class="content">
    <div id="homepage">
      <h1 style="text-align: center">{{ pageTitle }}</h1>
      <picture>
        <source media="(max-width:919px)" srcset="/images/home-mobile.png">
        <source media="(min-width:920px)" srcset="/images/home.png">
        <img src="/images/home-mobile.png" :alt="pageTitle" />
      </picture>
      <div class="blue-block">
        <p>ATLAS is led by expert clinicians, patient advocates, patient organisation leaders and Takeda to highlight the unmet needs of patients with intestinal failure and the inequalities that exist in care across Europe.</p>
        <router-link :to="{ name: 'Vision' }" class="button">Discover more…</router-link>
      </div>
    </div>
    <div class="pre-footer home">
      <div class="inner-wrapper">
        <div class="column">
          <a :class="{'dark-blue' : true, 'active': currentTab === 1}" @click.prevent.stop="currentTab = 1" href="#what-is-intestinal-failure">
            <img src="/images/what_is_if.svg" alt="What is IF?" /><span>What is intestinal failure?</span>
          </a>
        </div>
        <div class="column">
          <a :class="{'light-blue' : true, 'active': currentTab === 2}" @click.prevent.stop="currentTab = 2" href="#standard-of-care">
            <img src="/images/standard_of_care.svg" alt="Standard of care" /><span>Standard of care</span>
          </a>
        </div>
        <div class="column">
          <a :class="{'light-green' : true, 'active': currentTab === 3}" @click.prevent.stop="currentTab = 3" href="#resources">
            <img src="/images/resources.svg" alt="Resources" /><span>Resources</span>
          </a>
        </div>
      </div>
      <div class="inner-wrapper tabs">
        <div :class="{'tab': true, 'dark-blue' : true, 'hidden': currentTab !== 1}" id="what-is-intestinal-failure">
          <div class="tab-content">
            <p>Within the digestive system, intestinal failure (IF) is the rarest form of organ failure<sup>1</sup> and yet it does not have an ICD code.<sup>2</sup> As per the European Society for Clinical Nutrition and Metabolism (ESPEN) definition, intestinal failure is the reduction of large intestine function, below the minimum necessary for the absorption of macronutrients and/or water and electrolytes, such that intravenous supplementation is required to maintain a healthy human digestive system and/or growth.<sup>1</sup></p>
            <router-link class="button" :to="{name: 'Definition'}">Learn more about IF</router-link>
          </div>
        </div>
        <div :class="{'tab': true, 'light-blue' : true, 'hidden': currentTab !== 2}" id="standard-of-care">
          <div class="tab-content">
            <p>Our vision at ATLAS is for all Intestinal failure patients in Europe to receive an equal standard of digestive system function care by 2030, as documented through the improvement in patient outcomes. The ATLAS Country Dashboards aim to create a standardised benchmark platform from which to measure, monitor and talk about the intestinal failure (IF) treatment and the patient care landscape.</p>
            <router-link class="button" :to="{name: 'Dashboards'}">View the interactive dashboards</router-link>
          </div>
        </div>
        <div :class="{'tab': true, 'light-green' : true, 'hidden': currentTab !== 3}" id="resources">
          <div class="tab-content">
            <p>ATLAS was formed in 2016 as a response to a lack of awareness of intestinal failure and poor harmonisation of standards of care across Europe. Led by a Steering Committee of expert doctors in areas such as crohn's disease, irritable bowel syndrome, ulcerative colitis, constipation, colitis, inflammatory bowel disease and short bowl syndrome. The committee also covers patient advocates, patient organisation leaders and Takeda, ATLAS has developed resources for the intestinal failure and digestive system community.</p>
            <router-link class="button" :to="{name: 'Dashboards'}">View our resources</router-link>
          </div>
        </div>
      </div>
      <atlas-references style="margin: 60px 0 45px">
        <ol>
          <li>1. PIRONI, L. ET AL. (2016). ESPEN guidelines on chronic intestinal failure in adults. Clinical Nutrition. 35. 247-307.</li>
          <li>ICD. (2017). International Classification of Diseases (ICD). ICD-10 Version. Viewed 30 April 2020. <a href="http://apps.who.int/classifications/icd10/browse/2010/en#/XI" target="_blank" @click="externalURL" rel="nofollow">http://apps.who.int/classifications/icd10/browse/2010/en#/XI</a>.</li>
        </ol>
      </atlas-references>
    </div>
  </div>
</template>

<script>
import { description, pageTitle } from '@/variables.js'
import AtlasReferences from '@/components/References'
import externalURL from '@/external-url'

export default {
  components: {
    AtlasReferences
  },
  data () {
    return {
      pageTitle,
      currentTab: 1
    }
  },
  methods: {
    externalURL
  },
  metaInfo () {
    return {
      title: pageTitle,
      titleTemplate: null,
      meta: [
        { name: 'description', content: description },
        { name: 'keywords', content: 'Intestinal Failure, Digestive System, Inflammatory Bowel Disease, Crohn’s Disease, Irritable bowel syndrome, ibs symptoms' },
        { name: 'og:title', content: pageTitle },
        { name: 'og:description', content: description }
      ]
    }
  }
}
</script>

<style lang="scss">
  #homepage {
    position: relative;

    picture {
      position: relative;
      display: block;
    }

    .blue-block {
      position: relative;
      display: block;
      border-radius: 0 75px;
      margin: -50px 0 50px 0;
      padding: 25px;
      background: $darkBlue;
      font-size: 23px;
      font-weight: 700;
      color: $white;
      z-index: 100;

      p {
        font-size: 23px;
        line-height: 28px;
        color: $white;
        margin-bottom: 20px;
      }

      a {
        position: absolute;
        margin-left: 20px;
      }
    }
  }

  @media screen and (min-width: $mobile) {
    #homepage .blue-block {
      max-width: 360px;
      margin-top: -75px;
      margin-left: 50px;
    }
  }

  @media screen and (min-width: 860px) {
    #homepage .blue-block {
      margin-top: -96px;
      margin-left: 60px;
    }
  }

  @media screen and (min-width: $desktop) {
    #homepage {
      picture {
        max-width: 90%;
        margin-left: 10%;
      }

      .blue-block {
        @include verticalCenter();
        margin: 0;
      }
    }
  }

  .home .tabs {
    .tab {
      display: flex;
      margin: 30px 30px 0 30px;

      .tab-content {
        padding: 15px 15px 15px 30px;
      }

      &:before {
        content: '';
        padding-left: 8px;
        height: 100%;
        display: inline-block;
        border-radius: 50px;
      }

      &.hidden,
      &.hidden:before {
        display: none;
      }

      &.dark-blue {
        &:before,
        .button {
          background: $darkBlue;
        }

        .button:hover {
          background: $lightBlue;
        }
      }

      &.light-blue {
        &:before,
        .button {
          background: $lightBlue;
        }

        .button:hover {
          background: $darkBlue;
        }
      }

      &.light-green {
        &:before,
        .button {
          background: $lightGreen;
        }

        .button:hover {
          background: $darkGreen;
        }
      }
    }

    a {
      display: inline-block;
    }
  }

  .home.pre-footer {
    margin: 60px -20px 0;

    .column > a {
      cursor: pointer;

      &.active {
        span {
          border-bottom: 2px solid $grey;
        }

        &.dark-blue span {
          border-bottom-color: $darkBlue;
        }

        &.light-blue span {
          border-bottom-color: $lightBlue;
        }

        &.light-green span {
          border-bottom-color: $lightGreen;
        }
      }
    }
  }
</style>
