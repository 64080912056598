export default {
  pop: {
    value: '4,164,772',
    reference: {
      text: 'The Global Cancer Observatory (2018), Croatia, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/191-croatia-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '4.142',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 82.83,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 10.97,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '13,163',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) 2017, GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '17,654',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) 2017, GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: 1,
    reference: {
      text: 'KBC Zagreb, News page, viewed 21 August 2020',
      link: 'https://www.kbc-zagreb.hr/?s=zatajenja+crijevne+funkcije+'
    }
  },
  research_liver: {
    value: 0,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 13,
    reference: {
      text: 'Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [
    {
      name: 'HUCUK',
      type: '<abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Hrvatsko udruženje za Crohnovu bolest i Ulcerozni Kolitis. 2015. Homepage. Viewed 21 August 2020',
        link: 'https://hucuk.hr'
      }
    },
    {
      name: 'Colonos',
      type: '<abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Udruga oboljelih od Crohnove bolesti i ulceroznog kolitisa. 2019. Homepage. Viewed 21 August 2020',
        link: 'http://www.colonos.hr'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'Hrvatski zavod za zdravstveno osiguranje (HZZO) (2020), Home page, Hrvatski zavod za zdravstveno osiguranje (HZZO), viewed 21 August 2020',
      link: 'https://www.hzzo.hr/en/'
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
