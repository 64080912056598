export default {
  pop: {
    value: '17,084,467',
    reference: {
      text: 'The Global Cancer Observatory (2018), The Netherlands, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/528-the-netherlands-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '92.314',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 64.40,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 11.09,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '11.62',
    reference: {
      text: 'KELLY DG et al. (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.',
      link: null
    }
  },
  kidney: {
    value: '10,067',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '13,761',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: true,
    reference: {
      text: 'NEELIS, E. et al. (2016). Presentation of a nationwide multicenter registry of intestinal failure and intestinal transplantation. Clinical Nutrition. 35(1). 225-229.',
      link: null
    }
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: true,
    reference: {
      text: 'NABER, AHJ. et al. (2005). De behandeling van darmfalen met parenterale thuisvoeding bij kinderen en volwassenen. Ned Tijdschr Geneeskd. 149(8). 385-390. Viewed 21 August 2020.',
      link: 'https://www.ntvg.nl/system/files/publications/2005103850001a.pdf'
    }
  },
  ref_centers: {
    value: 4,
    reference: {
      text: 'UMC Groningen. 2020. Homepage. Viewed 21 August 2020, https://www.umcg.nl/NL/Zorg/Volwassenen/Expertisecentra-zeldzame-aandoeningen/Paginas/Darmfalen.aspx\n' +
        'Amsterdam UMC. 2020. Homepage. Viewed 21 August 2020, https://www.amc.nl/web/specialismen/bijzondere-zorg-expertisecentra/darmfalen/tpv/tpv-thuisteamdarmfalen-polikliniek.htm\n' +
        'Erasmus UMC. 2020. Homepage. Viewed 21 August 2020, https://www.erasmusmc.nl/nl-nl/sophia/patientenzorg/aandoeningen/darmfalen\n' +
        'UMC Radboud. 2020. Homepage. Viewed 21 August 2020, https://www.radboudumc.nl/expertisecentra/zeldzame-aandoeningen/chronische-darmfalen',
      link: null
    }
  },
  research_liver: {
    value: 4,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 52,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 4,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Crohn en Colitis Ulcerosa Vereniging Nederland (CCUVN)',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Crohn en Colitis Ulcerosa Vereniging Nederland. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.crohn-colitis.nl/'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'Zorginstituut Nederland (2018), Totale parenterale voeding (TPV) alleen vergoeden uit geneeskundige zorg, Zorginstituut Nederland, viewed on 21 August 2020',
      link: 'https://www.zorginstituutnederland.nl/actueel/nieuws/2018/08/07/totale-parenterale-voeding-alleen-vergoeden-uit-geneeskundige-zorg'
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
