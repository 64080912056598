<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>ATLAS was formed in 2016 as a response to a lack of awareness and poor harmonisation of standards of
    care across Europe.

    <p>Watch Professor Schneider discuss ATLAS and its principal findings:</p>
    <embed-video :src="'https://www.youtube.com/embed/ZRiu3O1t48k'"></embed-video>

    <p>Led by a <router-link :to="{ name: 'Committee'}">Steering Committee</router-link> of expert clinicians, patient advocates, patient organisation leaders and Takeda, ATLAS is focused on achieving the best possible treatment and care for all patients with <router-link :to="{ name: 'Definition'}">intestinal failure (IF)</router-link>.

    <p>To introduce the ATLAS Programme and its principal outcomes, Professor Simon Lal of the ATLAS Steering Committee interviewed Professor Stéphane Schneider, the Committee Chairman.

    <p class="bold">Unmet needs in IF</p>

    <p>The ATLAS programme was <a target="_blank" rel="nofollow" @click="externalURL" href="https://www.theparliamentmagazine.eu/event/atlas-launch-what-more-can-we-do-support-chronic-intestinal-failure-patients-discussion-rare">showcased at an event</a> in the European Parliament in March 2018. The event featured speeches from patients, leading gastrointestinal healthcare professionals, rare disease experts and EU policymakers. The event also featured a presentation of the <router-link :to="{ name: 'Policy'}">ATLAS Policy Paper</router-link>, which was originally developed in 2017<sup>1</sup> and has been revised in 2021.</p>

    <p class="bold">The unmet needs in IF are:<sup>1</sup></p>

    <ol class="blue-blocks">
      <li>Chronic IF is treatable, but there is inequity of access to treatment between and within countries</li>
      <li>There is a need to disseminate agreed epidemiology and quality standards within and between countries</li>
      <li>There is an opportunity to improve stakeholder education</li>
      <li>Current home and social care are inadequate to meet the quality of life needs of those with chronic IF and their families</li>
      <li>There is inadequate research to drive service improvement</li>
      <li>There is a lack of accredited reference centres</li>
    </ol>

    <p class="bold">Calls to action</p>
    <p>The ATLAS Steering Committee identified goals for policymakers, the healthcare community and the patient community to help address lack of awareness and poor harmonisation in standards of care across Europe.<sup>1</sup></p>
    <collapsible :title="'Goals for the healthcare community'" :reference="'1'">
      <h4>Expansion of the IF reference networks</h4>
      <p>Engage with European Reference Networks to drive pan-European dialogue between clinicians. Advise on the distribution of resources across the EU.</p>

      <h4>Improve patient quality of life needs</h4>
      <p>Establish protocols for a smooth transition from paediatric to adult care.</p>

      <h4>Improve stakeholder education</h4>
      <p>Train non-specialist clinicians and HCPs to manage patients within the healthcare system. Educate patients and carers so they can recognise and manage IF.</p>
    </collapsible>
    <collapsible :title="'Goals for the patient community'" :reference="'1'">
      <h4>Improve stakeholder awareness</h4>
      <p>Ensure awareness of IF during disease awareness events, working with patients and clinicians to drive policy change. Empower patient organisations to inform policymakers of the patient burden, unmet need and innovative treatment options.</p>
    </collapsible>
    <collapsible :title="'Goals for policymakers'" :reference="'1'">
      <h4>Recognise IF as an organ failure</h4>
      <ul>
        <li>Change policy so that people with IF have equal access to care and treatment.</li>
        <li>Collect and monitor data on prevalence and outcomes of IF care.</li>
        <li>Ensure that health and social care is well-integrated and reimbursed.</li>
        <li>Implement national guidelines throughout Europe recognising IF as a rare organ failure.</li>
      </ul>

      <h4>Classification of IF as a rare disease</h4>
      <ul>
        <li>Encourage the WHO to include IF in ICD coding.</li>
        <li>Support widespread recognition of IF as a rare disease at national and European level.</li>
      </ul>

      <h4>Expansion of the IF reference networks</h4>
      <ul>
        <li>European Commission should ensure that European Reference Networks are sufficiently equipped to provide leadership to improve health outcomes in IF.</li>
      </ul>
    </collapsible>
    <atlas-support></atlas-support>
    <atlas-references>
      <ol>
        <li>SCHNEIDER, S. ET AL. (2017). ATLAS – Transforming the vision – Giving chronic intestinal failure patients the care they need – a call to action. Shire International. 2-4.</li>
      </ol>
    </atlas-references>
  </div>
</template>

<script>
import EmbedVideo from '@/components/EmbedVideo'
import Collapsible from '@/components/Collapsible'
import AtlasSupport from '@/components/Support'
import AtlasReferences from '@/components/References'
import externalURL from '@/external-url'

export default {
  data () {
    return {
      title: 'History of ATLAS',
      description: 'ATLAS was formed in 2016 as a response to intestine function failure and poor intestine care standard harmonization of care across Europe.',
      keywords: 'ATLAS.eu, Constipation, ulcerative colitis, History, ibs symptoms'
    }
  },
  methods: {
    externalURL
  },
  components: {
    EmbedVideo,
    Collapsible,
    AtlasSupport,
    AtlasReferences
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
