<template>
  <div class="media-wrapper-outer">
    <div class="media-wrapper-inner">
      <iframe class="media" :src="src" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .media-wrapper-outer {
    display: block;
    margin: 30px auto 50px auto;
    max-width: 66%;
  }

  .media-wrapper-inner {
    position: relative;
    padding-bottom: 56.25%; /* 16/9 ratio */
    padding-top: 25px; /* IE6 workaround*/
    height: 0;
    overflow: hidden;

    iframe.media {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
