<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>Across Europe, there is great variance in terms of data, guidelines, registries and patient organisations for intestinal failure (IF), short bowel syndrome (SBS) and home parenteral nutrition (HPN).<sup>1</sup></p>
    <p>The following maps give an overview of the differences on the subjects of prevalence data for IF/SBS or HPN, national guidelines for IF & SBS, registries for IF, SBS and HPN, and patient organisations for SBS, HPN and home artificial nutrition (HAN).</p>
    <a href="/files/leavebehind.pdf" class="download" target="_blank" title="Download the leave behind PDF">
      <img srcset="/images/pages/leavebehind@2x.jpg 2x, /images/pages/leavebehind.jpg 1x" src="/images/pages/leavebehind.jpg" :alt="title" />
      <span>Download as PDF</span>
    </a>
    <atlas-support></atlas-support>
    <atlas-references>
      <ol class="continue">
        <li>SCHNEIDER, S. ET AL. (2017). ATLAS – Transforming the vision – Giving chronic intestinal failure patients the care they need – a call to action. Shire International.</li>
      </ol>
      <p class="bold">Map references</p>
      <p class="bold">Prevalence:</p>
      <ol class="continue reset">
        <li><div>Belgium. UMBRAIN, N. et al. (2019). Impact of nutrition support team (NST) funding from the Belgian National Health Insurance (NHI) on the quality of care of home parenteral nutrition (HPN) in adults with benign diseases. Clinical Nutrition. 38. S59-S296.</div></li>
        <li><div>Czech Republic. BUDINSKA, E. et al. (2019). Microbiome and Metabolome Profiles Associated with Different Types of Short Bowel Syndrome: Implications for Treatment. Journal of Parenteral and Enteral Nutrition. 44(1). 105-118.</div></li>
        <li><div>Denmark. JEPPESEN, P. (2014). Spectrum of Short Bowel Syndrome in Adults Intestinal Insufficiency to Intestinal Failure. Journal of Parenteral and Enteral Nutrition. 38(1). 8S–13S.</div></li>
        <li><div>France. KELLY, DG. et al (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.</div></li>
        <li><div>Germany. VON WEBSKY, M. et al. (2014) Das Kurzdarmsyndrom in Deutschland. Der Chirurg. 85. 433–439.</div></li>
        <li><div>Ireland. BELL, A. et al. (2018). Point Prevalence of Adult Intestinal Failure in Republic of Ireland. Irish Medical Journal. 111(2). 688.</div></li>
        <li><div>Italy. DIAMANTI, A. et al. (2017). Pediatric Chronic Intestinal Failure in Italy Report from the 2016 Survey on Behalf of Italian Society for Gastroenterology, Hepatology and Nutrition (SIGENP). Nutrients. 9(11).</div></li>
        <li><div>Netherlands. KELLY DG et al. (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.</div></li>
        <li><div>Netherlands. NEELIS, E. et al. (2016). Presentation of a nationwide multicenter registry of intestinal failure and intestinal transplantation. Clinical Nutrition. 35(1). 225-229.</div></li>
        <li><div>Poland. JEPPESEN, P. (2014). Spectrum of Short Bowel Syndrome in Adults: Intestinal Insufficiency to Intestinal Failure. Journal of Parenteral and Enteral Nutrition. 38(1). 8S–13S.</div></li>
        <li><div>Poland. KELLY, DG. et al. (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.</div></li>
        <li><div>Spain. KELLY, DG. et al. (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.</div></li>
        <li><div>United Kingdom. KELLY, DG. et al. (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.</div></li>
        <li><div>United Kingdom. SMITH, T. et al. (2016). BANS Report 2016: Artificial Nutrition Support in the UK 2005-2015 - Adult Home Parenteral Nutrition. BAPEN (British Association of Parenteral and Enteral Nutrition).</div></li>
      </ol>

      <p class="bold">National Guidelines:</p>
      <ol class="continue">
        <li><div>Belgium. BAXTER, JP. et al. (2012). Home parenteral nutrition: An international benchmarking exercise. Clinical Nutrition. 7(5). E211-214.</div></li>
        <li><div>Czech Republic. Společnost klinické výživy a intenzivní metabolické péce (SKVIMP) (2020), Doporučené postupy – Guidelines, Společnost klinické výživy a intenzivní metabolické péce (SKVIMP), viewed 21 August 2020 <a target="_blank" @click="externalURL" rel="nofollow" href="http://www.skvimp.cz/podklady/doporucene-postupy-guidelines/">http://www.skvimp.cz/podklady/doporucene-postupy-guidelines/</a>.</div></li>
        <li><div>Czech Republic. Společnost klinické výživy a intenzivní metabolické péce (SKVIMP) (2020), Doporučené postupy, Společnost klinické výživy a intenzivní metabolické péce (SKVIMP), viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="http://www.skvimp.cz/pracovni-skupiny/domaci-parentalni-vyziva/doporucene-postupy/">http://www.skvimp.cz/pracovni-skupiny/domaci-parentalni-vyziva/doporucene-postupy/</a>.</div></li>
        <li><div>Germany. GPGE Gesellschaft für Pädiatrische Gastroenterologie und Ernährung 2018, Positionspapier Teduglutid, Gesellschaft für Pädiatrische Gastroenterologie und Ernährung, viewed on 21 August 2020 <a target="_blank" @click="externalURL" rel="nofollow" href="https://static1.squarespace.com/static/55db8048e4b089b9924893b3/t/5aa7d0f1c83025af3fd1a8df/1520947443695/1803_Positionspapier_GPGE_ Teduglutid_Konsens.pdf">https://static1.squarespace.com/static/55db8048e4b089b9924893b3/t/5aa7d0f1c83025af3fd1a8df/1520947443695/1803_Positionspapier_GPGE_ Teduglutid_Konsens.pdf</a>.</div></li>
        <li><div>Germany. LAMPRECHT, G. et al. (2014). S3-Leitlinie der Deutschen Gesellschaft für Ernährungsmedizin e. V. in Zusammenarbeit mit der AKE, der GESKES und der DGVS. Aktuelle Ernährungsmedizin, 39(02). e57-e71.</div></li>
        <li><div>Germany. Gesellschaft für Pädiatrische Gastroenterologie und Ernährung (2019), Die Zukunft liegt im gesunden Kinderbauch, Gesellschaft für Pädiatrische Gastroenterologie und Ernährung, viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.gpge.eu/leitlinien">https://www.gpge.eu/leitlinien</a>.</div></li>
        <li><div>Spain. SEGHNP Sociedad Española de Gastroenterología Pediatrica, viewed 21 August 2020 <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.seghnp.org/documentos?field_tipo_de_documento_target_id_1=11">https://www.seghnp.org/documentos?field_tipo_de_documento_target_id_1=11</a>.</div></li>
        <li><div>Sweden. ELLEGÅRD, L. et al. (2016), Tarmsvikt – Handläggning och behandling, Svensk Gastroenterologisk Förening, viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https:// svenskgastroenterologi.se/wp-content/uploads/2017/06/2016-Tarmsvikt.pdf">https:// svenskgastroenterologi.se/wp-content/uploads/2017/06/2016-Tarmsvikt.pdf</a>.</div></li>
        <li><div>United Kingdom. British Intestinal Failure Alliance (2018), Use of peptide growth factors for adult patients with intestinal failure. British Association for Parenteral and Enteral Nutrition, viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.bapen.org.uk/pdfs/bifa/position-statements/use-of-peptide-growth-factors-for-adult-patients-with-intestinal-failure-draft.pdf">https://www.bapen.org.uk/pdfs/bifa/position-statements/use-of-peptide-growth-factors-for-adult-patients-with-intestinal-failure-draft.pdf</a>.</div></li>
      </ol>
      <p class="bold">Registries:</p>
      <ol class="continue">
        <li><div>Belgium. UMBRAIN, N. et al. (2019). Impact of nutrition support team (NST) funding from the Belgian National Health Insurance (NHI) on the quality of care of home parenteral nutrition (HPN) in adults with benign diseases. Clinical Nutrition. 38. S59-S296.</div></li>
        <li><div>Czech Republic. TESINSKY, P. et al. (2015). MON-PP197: Home Parenteral Nutrition National Registry in Czech Republic: Annual Report. Clinical Nutrition. 34. S201.</div></li>
        <li><div>Denmark. BRANDT, C. et al. (2016). Home Parenteral Nutrition in Adult Patients with Chronic Intestinal Failure: Catheter-Related Complications Over 4 Decades at the Main Danish Tertiary Referral Center. Journal of Parenteral and Enteral Nutrition. 1-8.</div></li>
        <li><div>Denmark. FUGSLANG, K. et al. (2020). Hospitalizations in Patients with Nonmalignant Short-Bowel Syndrome Receiving Home Parenteral Support. Nutrition in Clinical Practice. 0. 1-9.</div></li>
        <li><div>Ireland. RICE, N. et al. (2013). A Review of Home Parenteral Nutrition in Ireland: Recommendations for Action. IrSPEN Special Report. 1. 5-39.</div></li>
        <li><div>Italy . LEZO, A. et al. (2018). Paediatric Home Artificial Nutrition in Italy: Report from 2016 Survey on Behalf of Artificial Nutrition Network of Italian Society for Gastroenterology. Hepatology and Nutrition (SIGENP). Nutrients. 10(9). 1311.</div></li>
        <li><div>Netherlands . NEELIS, E. et al. (2016). Presentation of a nationwide multicenter registry of intestinal failure and intestinal transplantation. Clinical Nutrition. 35(1). 225-229.</div></li>
        <li><div>Netherlands. NABER, AHJ. et al. 2005). De behandeling van darmfalen met parenterale thuisvoeding bij kinderen en volwassenen. Ned Tijdschr Geneeskd. 149(8). 385-390. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.ntvg.nl/system/files/publications/2005103850001a.pdf">https://www.ntvg.nl/system/files/publications/2005103850001a.pdf</a>.</div></li>
        <li><div>Norway. Biobank Norway, Norwegian health data, viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://bbmri.no/health-data">https://bbmri.no/health-data</a>.</div></li>
        <li><div>Spain. Spanish Rare Diseases Registries Research Network Instituto de Salud Carlos III, viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://spainrdr.isciii.es/es/Paginas/default.aspx">https://spainrdr.isciii.es/es/Paginas/default.aspx</a>.</div></li>
        <li><div>Spain. WANDEN-BERGHE, C. et al. (2018). Home and Ambulatory Artificial Nutrition (NADYA) Report. Home Parenteral Nutrition in Spain. Clinical Nutrition. 35(6). 1491-1496.</div></li>
        <li><div>United Kingdom. British Artificial Nutrition Survey 2020, BANS Reports, British Association for Parenteral and Enteral Nutrition, viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.bapen.org.uk/resources-and-education/publications-and-reports/bans">https://www.bapen.org.uk/resources-and-education/publications-and-reports/bans</a>.</div></li>
      </ol>
      <p class="bold">Patient Organisations:</p>
      <ol class="continue">
        <li><div>Austria. Die-chronischen-experten. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.die-chronischen-experten.at/"></a>.</div></li>
        <li><div>Austria. Österreichische Morbus Crohn / Colitis Ulcerosa Vereinigung. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.oemccv.at/"></a>.</div></li>
        <li><div>Belgium. Hello TPN. 2019. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.vzwhellotpn.be">https://www.vzwhellotpn.be</a>.</div></li>
        <li><div>Belgium. La Vie par un Fil. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="http://www.lavieparunfilbelgique.be/index.php">http://www.lavieparunfilbelgique.be/index.php</a>.</div></li>
        <li><div>Bulgaria. Национална пациентска организация. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.npo.bg">https://www.npo.bg</a>.</div></li>
        <li><div>Croatia. Hrvatsko udruženje za Crohnovu bolest i Ulcerozni Kolitis. 2015. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://hucuk.hr/">https://hucuk.hr/</a>.</div></li>
        <li><div>Croatia. Udruga oboljelih od Crohnove bolesti i ulceroznog kolitisa. 2019. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="http://www.colonos.hr/">http://www.colonos.hr/</a>.</div></li>
        <li><div>Czech Republic. Život bez střeva. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://zivotbezstreva.cz/">https://zivotbezstreva.cz/</a>.</div></li>
        <li><div>Denmark. HPN-foreningen - en frivillig patientorganisation. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://hpn.dk/">https://hpn.dk/</a>.</div></li>
        <li><div>Denmark. Colitis-Crohn Foreningen. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://ccf.dk/">https://ccf.dk/</a>.</div></li>
        <li><div>Estonia. Eesti Põletikulise Soolehaiguse Selts. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="http://www.ibd.ee/">http://www.ibd.ee/</a>.</div></li>
        <li><div>Finland. Crohn ja Colitis. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://crohnjacolitis.fi/">https://crohnjacolitis.fi/</a>.</div></li>
        <li><div>France. La Vie par un Fil. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.lavieparunfil.com/">https://www.lavieparunfil.com/</a>.</div></li>
        <li><div>Germany. Selbsthilfe Kurzdarmsyndrom. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="http://selbsthilfe-kurzdarmsyndrom.de/">http://selbsthilfe-kurzdarmsyndrom.de/</a>.</div></li>
        <li><div>Germany. Kinder in Schwieriger Ernährungssituation. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="http://kise-ev.de/">http://kise-ev.de/</a>.</div></li>
        <li><div>Germany. Koordination Kurzdarmsyndrom. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.koordination-kurzdarmsyndrom.de/">https://www.koordination-kurzdarmsyndrom.de/</a>.</div></li>
        <li><div>Italy. Un filo per la vita. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="http://www.unfiloperlavita.it/">http://www.unfiloperlavita.it/</a>.</div></li>
        <li><div>Netherlands. Crohn en Colitis Ulcerosa Vereniging Nederland. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.crohn-colitis.nl/">https://www.crohn-colitis.nl/</a>.</div></li>
        <li><div>Norway. NORILCO. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.norilco.no/">https://www.norilco.no/</a>.</div></li>
        <li><div>Norway. Norsk Intravenøsforening. 2018. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.nifo.no/">https://www.nifo.no/</a>.</div></li>
        <li><div>Norway. Landsforeningen mot fordøyelsessykdommer. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.lmfnorge.no/">https://www.lmfnorge.no/</a>.</div></li>
        <li><div>Poland. Apetyt na Życie. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://apetytnazycie.org/en/homepage-eng/">https://apetytnazycie.org/en/homepage-eng/</a>.</div></li>
        <li><div>Slovenia. Društvo za kronično vnetno črevesno bolezen. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.kvcb.si/">https://www.kvcb.si/</a>.</div></li>
        <li><div>Slovenia. Invalidsko društvo Kengurujček Slovenije. 2017. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="http://www.kengurujcek.si/">http://www.kengurujcek.si/</a>.</div></li>
        <li><div>Spain. Asociación de niños, adultos y familias con fallo intestinal, nutrición parenteral y trasplante múltiple. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://somosnupa.org/">https://somosnupa.org/</a>.</div></li>
        <li><div>Spain. Asociación valenciana de niños con síndrome de intestino corto y dependientes de nutrición parenteral. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.solucionesong.org/ong/asociacion-valenciana-de-ninos-con-sindrome-de-intestino-corto-y-dependientes-de-nutricion-parenteral-asvanic/10160/view">https://www.solucionesong.org/ong/asociacion-valenciana-de-ninos-con-sindrome-de-intestino-corto-y-dependientes-de-nutricion-parenteral-asvanic/10160/view</a>.</div></li>
        <li><div>Sweden. Svenska HPN-Föreningen. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.hpn.se/">https://www.hpn.se/</a>.</div></li>
        <li><div>Sweden. ILCO. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.ilco.nu/">https://www.ilco.nu/</a>.</div></li>
        <li><div>Switzerland. Magendarmliga Schweiz. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.gastromed.ch/">https://www.gastromed.ch/</a>.</div></li>
        <li><div>Switzerland. Crohn Colitis Schweiz. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.crohn-colitis.ch/">https://www.crohn-colitis.ch/</a>.</div></li>
        <li><div>United Kingdom. Patients on Intravenous and Naso-gastric Nutrition Treatment. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://pinnt.com/About-Us.aspx">https://pinnt.com/About-Us.aspx</a>.</div></li>
        <li><div>United Kingdom. Short Bowel Survivor & friends. 2020. Homepage. Viewed 21 August 2020, <a target="_blank" @click="externalURL" rel="nofollow" href="https://www.shortbowelsurvivor.co.uk/">https://www.shortbowelsurvivor.co.uk/</a>.</div></li>
      </ol>
    </atlas-references>
  </div>
</template>

<script>
import AtlasSupport from '@/components/Support'
import AtlasReferences from '@/components/References'
import externalURL from '@/external-url'

export default {
  data () {
    return {
      title: 'An overview of IF care in Europe',
      description: 'In Europe there are data, guidelines, registries and patient groups variances for IF, irritable bowel syndrome, short bowl syndrome and HPN.',
      keywords: 'Intestinal Failure, Patient Groups, Patient Registries, Inflammatory Bowel Disease, Crohn’s Disease, Irritable bowel syndrome'
    }
  },
  methods: {
    externalURL
  },
  components: {
    AtlasSupport,
    AtlasReferences
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
