export default {
  pop: {
    value: '82,293,462',
    reference: {
      text: 'The Global Cancer Observatory (2018), Germany, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/276-germany-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '444.150',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP.',
      link: null
    }
  },
  gov_exp: {
    value: 77.66,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 12.67,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '34',
    reference: {
      text: 'VON WEBSKY, M. et al. (2014) Das Kurzdarmsyndrom in Deutschland. Der Chirurg. 85. 433–439.',
      link: null
    }
  },
  kidney: {
    value: '10,861',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '13,880',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: 24,
    reference: {
      text: 'Orphanet, Search for Expert Centres and Networks, viewed 21 August 2020',
      link: 'https://www.orpha.net/consor/cgi-bin/Clinics_Search.php?lng=EN&type_list=clinics_search_simple_shd&data_id=20909&Disease(s)/group%20 of%20diseases=Chronic-intestinal-failure&title=Chronic%20intestinal%20failure&search=Clinics_Search_Simple&ChdId=20909&Clinics_Clinics_Search_diseaseGroup=Chronic-intestinal-failure&Clinics_Clinics_Search_diseaseType=Pat&Clinics_Clinics_Search_CnsType=n&Clinics_Clinics_Search_age=Both&Clinics_Clinics_Search_country=DE&Clinics_Clinics_Search_GeographicType=Cnt'
    }
  },
  research_liver: {
    value: 18,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 205,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 5,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Selbsthilfe Kurzdarmsyndrom',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Selbsthilfe Kurzdarmsyndrom. 2020. Homepage. Viewed 21 August 2020',
        link: 'http://selbsthilfe-kurzdarmsyndrom.de'
      }
    },
    {
      name: 'Kindern in schwieriger Ernährungslage',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Kinder in Schwieriger Ernährungssituation. 2020. Homepage. Viewed 21 August 2020',
        link: 'http://kise-ev.de'
      }
    },
    {
      name: 'Koordination Kurzdarmsyndrom',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Koordination Kurzdarmsyndrom. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.koordination-kurzdarmsyndrom.de'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'MORENO, J. et al. (2001). Survey on legislation and funding of Home Artificial Nutrition in different European countries. Clinical Nutrition. 20(2). 117-123',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: true,
    reference: {
      text: 'GPGE Gesellschaft für Pädiatrische Gastroenterologie und Ernährung 2018, Positionspapier Teduglutid, Gesellschaft für Pädiatrische Gastroenterologie und Ernährung, viewed on 21 August 2020',
      link: 'https://static1.squarespace.com/static/55db8048e4b089b9924893b3/t/5aa7d0f1c83025af3fd1a8df/1520947443695/1803_Positionspapier_GPGE_Teduglutid_Konsens.pdf'
    }
  },
  nat_guidelines_sbs: {
    value: true,
    reference: {
      text: 'LAMPRECHT, G. et al. (2014). S3-Leitlinie der Deutschen Gesellschaft für Ernährungsmedizin e. V. in Zusammenarbeit mit der AKE, der GESKES und der DGVS. Aktuelle Ernährungsmedizin, 39(02). e57-e71.\n' +
        'Gesellschaft für Pädiatrische Gastroenterologie und Ernährung (2019), Die Zukunft liegt im gesunden Kinderbauch, Gesellschaft für Pädiatrische Gastroenterologie und Ernährung, viewed 21 August 2020, https://www.gpge.eu/leitlinien.',
      link: null
    }
  }
}
