export default {
  pop: {
    value: '8,544,026',
    reference: {
      text: 'The Global Cancer Observatory (2018), Switzerland, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/756-switzerland-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '87.068',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 30.49,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 28.95,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '9,788',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '11,768',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: 1,
    reference: {
      text: 'Orphanet, Search for Expert Centres and Networks, viewed 21 August 2020',
      link: 'https://www.orpha.net/consor/cgi-bin/Clinics_Search.php?lng=EN&data_id=125775&Expert%20centres=Pediatric-care-clinic-digestive-disease-with-intestinal-failure&title=Pediatric%20care%20clinic%20digestive%20 disease%20with%20intestinal%20failure&search=Clinics_Search_Simple'
    }
  },
  research_liver: {
    value: 2,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 37,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Magendarmliga Schweiz',
      type: '<abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Magendarmliga Schweiz. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.gastromed.ch'
      }
    },
    {
      name: 'Crohn Colitis Switzerland',
      type: 'Crohn’s disease and <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Crohn Colitis Schweiz. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.crohn-colitis.ch'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'BALLMER, E. et al. (2013), Richtlinien der GESKES über Home Care, künstliche Ernährung zu Hause, GESKES, viewed 21 August 2020',
      link: 'https://www.bag.admin.ch/dam/bag/de/dokumente/kuv-leistungen/referenzdokumente-klv-anhang-1/02.1-richtlinien-geskes--homecare-kuenstliche-ernaehrung-zu-hause-vom-januar2013.pdf.download.pdf/'
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'BALLMER, E. et al. (2013), Richtlinien der GESKES über Home Care, künstliche Ernährung zu Hause, GESKES, viewed 21 August 2020',
      link: 'https://www.bag.admin.ch/dam/bag/de/dokumente/kuv-leistungen/referenzdokumente-klv-anhang-1/02.1-richtlinien-geskes--homecare-kuenstliche-ernaehrung-zu-hause-vom-januar2013.pdf.download.pdf/'
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
