<template>
    <header>
      <div class="bar blue"></div>
      <div class="bar green"></div>
      <div class="inner-wrapper">
        <div class="logo">
          <router-link :to="{ name: 'Home' }" title="Home"><img src="/images/logo.svg" :alt="atlas" /></router-link>
        </div>
        <div :class="{'menu-toggle': true, 'nav-open': open }">
          <img class="close" src="/images/menu_close.svg" alt="Close menu" @click="open=false" />
          <img src="/images/menu_open.svg" class="open" alt="Open menu" @click="open=true" />
        </div>
        <nav>
          <ul>
            <li><router-link :to="{ name: 'Home' }" active-class="active" exact>Home</router-link></li>
            <li><span>What is ATLAS</span>
              <ul>
                <li><router-link active-class="active" :to="{ name: 'Vision' }">Vision, mission and approach</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'History' }">History of ATLAS</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'Committee' }">ATLAS Steering Committee</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'Events' }">Events</router-link></li>
              </ul>
            </li>
            <li><span>What is IF</span>
              <ul>
                <li><router-link active-class="active" :to="{ name: 'Definition' }">Definition, causes and symptoms</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'Needs' }">Unmet needs</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'Lack' }">Lack of ICD code for SBS and IF</router-link></li>
              </ul>
            </li>
            <li><span>Standard of care</span>
              <ul>
                <li><router-link active-class="active" :to="{ name: 'Map' }">IF treatment and care across Europe</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'OverviewEurope' }">An overview of Europe</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'CaseStudy' }">Patient case study</router-link></li>
              </ul>
            </li>
            <li><span>Resources</span>
              <ul>
                <li><router-link active-class="active" :to="{ name: 'Dashboards' }">Dashboards</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'Infographic' }">Infographics</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'ReferenceCentres' }">Reference centres</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'ComparisonMaps' }">IF vs other organ failures</router-link></li>
                <li><router-link active-class="active" :to="{ name: 'Policy' }">Policy Paper</router-link></li>
              </ul>
            </li>
            <li>
              <span>About {{ takeda }}</span>
              <ul>
                  <li><router-link active-class="active" :to="{ name: 'About' }">About {{ takeda }}</router-link></li>
                  <li><router-link active-class="active" :to="{ name: 'Contact' }">Contact us</router-link></li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </header>
</template>

<script>
import { atlas, takeda } from '@/variables.js'

export default {
  data () {
    return {
      takeda,
      atlas,
      open: false
    }
  },
  watch: {
    $route () {
      this.open = false
    }
  }
}
</script>

<style lang="scss">
  header {
    margin-bottom: 75px;

    .inner-wrapper {
      padding-top: 50px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .logo {
      width: 100px;
    }

    .menu-toggle {
      width: 24px;

      .close {
        display: none;
      }

      &.nav-open .open {
        display: none;
      }

      &.nav-open .close {
        display: block;
        width: 24px;
        position: fixed;
        top: 60px;
        right: 30px;
        z-index: 1100;
      }
    }
  }

  @media screen and (max-width: $desktop - 1px) {
    header nav {
      display: none;
    }

    .menu-toggle.nav-open + nav {
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      width: 100vw;
      min-height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      padding: 20px;
      background: $darkBlue;
      color: $white;
      text-align: center;
      z-index: 1000;

      > ul {
        margin-top: 40px;

        a {
          color: $white;
          padding: 3px 0;
          font-size: 16px;
          display: inline-block;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        > li {
          margin-bottom: 25px;

          > a,
          > span {
            font-size: 18px;
            font-weight: bold;
          }

          > span {
            font-style: italic;

            &:before,
            &:after {
              content: '—';
              display: inline-block;
              vertical-align: top;
              font-size: 15px;
              font-weight: 400;
            }

            &:before {
              margin-right: 5px;
            }

            &:after {
              margin-left: 7px;
            }
          }
        }
      }

      li {
        list-style: none;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    header .menu-toggle {
      display: none;
    }

    header nav {
      align-self: center;

      ul {
        display: inline-flex;

        li {
          display: inline-block;
          list-style: none;
        }

        ul {
          display: none;
        }

        > li {

          + li {
            margin-left: 5px;
          }

          > a,
          span {
            padding: 10px;
            font-size: 18px;
            text-decoration: none;
            color: $darkBlue;
            cursor: pointer;
          }

          /*a.active,*/
          &:hover > a,
          &:hover span {
            background: $lightGreen;
            color: $white;
          }
        }

        li span:hover + ul,
        li > ul:hover {
          position: absolute;
          padding-top: 20px;
          display: block;
          z-index: 1000;
        }

        li > ul > li {
          margin: 0;
          display: inline-block;
          width: 100%;

          a {
            width: auto;
            display: inline-block;
            padding: 10px;
            background: $darkGreen;
            color: $white;

            &:hover {
              background: $lightGreen;
            }
          }

          + li {
            margin-top: 5px;
          }
        }
      }
    }

    @media screen and (min-width: $maxWidth) {
      header nav ul > li + li {
        margin-left: 15px;
      }
    }
  }

</style>
