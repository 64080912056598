<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>The ATLAS <router-link :to="{ name: 'Committee' }">Steering Committee</router-link> has developed a policy paper for use in engaging with policymakers across Europe on our shared vision for patients with intestinal failure.  The report details policy recommendations that can be implemented to make this happen.</p>

    <p>The policy report includes the following:</p>
    <ul>
      <li>Executive Summary</li>
      <li>What is intestinal failure?</li>
      <li>The burden of intestinal failure</li>
      <li>Our vision for patients with intestinal failure
        <ul style="margin-top: 5px">
          <li>Speeding up diagnosis</li>
          <li>Delivering holistic care for patients</li>
          <li>Providing access to the right treatments at the right time</li>
          <li>Collaborating to share best practice and data</li>
        </ul>
      </li>
    </ul>

    <p><a target="_blank" href="/files/policy-paper.pdf" class="button">Read the complete policy paper here</a><br /><br /></p>
  </div>
</template>

<script>

export default {
  data () {
    return {
      title: 'From failure to hope: An agenda for change in Intestinal Failure',
      description: 'To raise awareness of intestinal failure this intestine policy paper for doctors, policymakers and patient care organisations.',
      keywords: 'Intestinal Failure, Awareness, Intestine Care Policy, Inflammatory bowel disease, Crohn’s Disease, Irritable bowel syndrome'
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
