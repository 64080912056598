export default {
  pop: {
    value: '10,625,250',
    reference: {
      text: 'The Global Cancer Observatory (2018), Czech Republic, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/203-czechia-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '17.714',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 81.75,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 14.81,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: 14,
    reference: {
      text: 'Microbiome and Metabolome Profiles Associated with Different Types of Short Bowel Syndrome: Implications for Treatment. Journal of Parenteral and Enteral Nutrition. 44(1). 105-118',
      link: null
    }
  },
  kidney: {
    value: '13,015',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '15,689',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: true,
    reference: {
      text: 'TESINSKY, P. et al. (2015). MON-PP197: Home Parenteral Nutrition National Registry in Czech Republic: Annual Report. Clinical Nutrition. 34. S201.',
      link: null
    }
  },
  ref_centers: {
    value: null,
    reference: null
  },
  research_liver: {
    value: 0,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 12,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Život bez střeva, z.s',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>',
      reference: {
        text: 'Život bez střeva. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://zivotbezstreva.cz'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'MORENO, J. et al. (2001). Survey on legislation and funding of Home Artificial Nutrition in Different European Countries. Clinical Nutrition 20(2). 117-123',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'MORENO, J. et al. (2001). Survey on legislation and funding of Home Artificial Nutrition in Different European Countries. Clinical Nutrition 20(2). 117-123',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: true,
    reference: {
      text: 'Společnost klinické výživy a intenzivní metabolické péce (SKVIMP) (2020), Doporučené postupy – Guidelines, Společnost klinické výživy a intenzivní metabolické péce (SKVIMP), viewed 21 August 2020',
      link: 'http://www.skvimp.cz/podklady/doporucene-postupy-guidelines/'
    }
  }
}
