<template>
  <div class="content with-sidebar">
    <h1>{{ title }}</h1>
    <p>ATLAS was formed in 2016 as a response to a lack of awareness of <router-link :to="{name: 'Definition'}">intestinal failure (IF)</router-link> and poor harmonisation of standards of care across Europe.<br />
      Led by a <router-link :to="{name: 'Committee'}">Steering Committee</router-link> of expert clinicians, patient advocates, patient organisation leaders and <router-link :to="{name: 'About'}">Takeda</router-link>, ATLAS has been focused on highlighting the <router-link :to="{name: 'Needs'}">unmet needs</router-link> of patients with IF and the inequalities that exist in care across Europe. The ATLAS Programme is initiated and funded by Takeda.</p>
  <img srcset="/images/pages/vision.png 1x, /images/pages/vision@2x.png 2x"   src="/images/pages/vision@2x.png" :alt="title" />

    <p>The vision of the ATLAS Steering Committee is for <span class="bold light-green">all IF patients in Europe receive an equal standard of care by 2030</span>, as documented through the improvement in patient outcomes.</p>

    <p>To turn this vision into a reality, ATLAS is working with stakeholders across Europe to increase awareness of IF, improve the diagnosis rate, facilitate the sharing of clinical excellence across borders and alert the scientific community to inequalities in the provision of care.</p>

    <p>This will be achieved through enlisting and empowering a community of IF stakeholders to:</p>
    <ul>
      <li>Raise disease awareness</li>
      <li>Advocate for inclusion within the International Classification of Diseases (ICD) coding</li>
      <li>Help to develop centres of excellence across Europe</li>
    </ul>

    <atlas-support></atlas-support>
  </div>
</template>

<script>
import AtlasSupport from '@/components/Support'

export default {
  data () {
    return {
      title: 'Vision, mission and approach',
      description: 'Find out ATLAS’ unique mission, vision and approach focusing on intestinal failure and gastrointestinal diseas',
      keywords: 'Intestinal Failure, Gastrointestinal Disease, ATLAS.eu, Bowel Obstructio'
    }
  },
  components: {
    AtlasSupport
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
