<template>
  <div :class="{ collapsible: true, collapsed: collapsed, blue: blue }">
    <div class="header" @click="collapsed = !collapsed">
      <h3><span v-html="title"></span><sup v-if="reference !== ''">{{ reference }}</sup></h3>
      <img src="/images/menu_close.svg" alt="Close menu" class="toggle" />
    </div>
    <div class="body-wrapper">
      <div class="body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      collapsed: true
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    reference: {
      type: String,
      required: false,
      default: ''
    },
    blue: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
  .collapsible {
    margin-bottom: 25px;

    .header {
      cursor: pointer;
      position: relative;
      background: $lightGreen;
      border-radius: 44px;
      padding: 20px 30px;
      font-size: 20px;
      line-height: 25px;
      font-weight: bold;
      letter-spacing: 0.043em;
      color: $white;

      h3 {
        max-width: calc(100% - 26px);
      }

      .toggle {
        @include verticalCenter();
        right: 20px;
        width: 36px;
        transition-duration: 0.3s;
        transition-property: transform;
      }
    }

    &.blue .header {
      background: $darkBlue;
    }

    .body-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:after {
        content: '';
        height: 1px;
        transition: height 0.5s linear, max-height 0s 0.5s linear;
        max-height: 0;
      }

      .body {
        transition: margin-bottom 0.5s cubic-bezier(0, 0, 0, 1);
        margin-bottom: 0;
        max-height: 1000000px;
        padding: 30px 35px;

        h4 {
          font-weight: bold;
          color: $lightGreen;
          font-size: 20px;
          line-height: 23px;
        }

        p,
        ul li {
          font-size: 20px;
          line-height: 23px;
        }

        ul {
          margin-top: 10px;
          margin-bottom: 30px;
        }

        p:last-child {
          margin-bottom: 0;
        }

        ul:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.collapsed {
      .header .toggle {
        -webkit-transform: translateY(-50%) rotate(45deg);
        -moz-transform: translateY(-50%) rotate(45deg);
        -o-transform: translateY(-50%) rotate(45deg);
        -ms-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
      }

      .body-wrapper {
        &:after {
          height: 0;
          transition: height 0.5s linear;
          max-height: 1px;
        }

        .body {
          margin-bottom: -2000px;
          transition: margin-bottom 0.5s cubic-bezier(1, 0, 1, 1),
          visibility 0s 0.5s,
          max-height 0s 0.5s;
          visibility: hidden;
          max-height: 0;
        }
      }
    }
  }
</style>
