<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>To alleviate the burden of intestinal failure (IF) on patients, healthcare systems and society, ATLAS has identified six key areas of unmet need to be addressed.<sup>1</sup></p>

    <unmet-need :id="1" :title="'IF is treatable, but there is inequality of access to treatment between and within countries'">
      <ul>
        <li>Currently, IF lacks a complete set of widely implemented country-level guidelines. Only four of 24 European countries have national IF guidelines.<sup>2,3,4,5</sup></li>
      </ul>

      <p class="bold light-green">How can healthcare professionals (HCPs) and policymakers help?<sup>1</sup></p>
      <ul>
        <li>HCPs should implement country-level guidelines on treating and managing IF or adopt the ESPEN guidelines.</li>
        <li>Policymakers should oversee the implementation of national guidelines on treating and managing IF or by adopting ESPEN guidelines.</li>
        <li>Policymakers should ensure that European Reference Networks (ERNs) are sufficiently equipped and resourced to provide leadership to improve Europe-wide health outcomes in IF.</li>
      </ul>
    </unmet-need>

    <unmet-need :id="2" :title="'There is a need to disseminate agreed epidemiology and quality standards within and between countries.'">
      <ul>
        <li>A lack of clear epidemiological data makes it difficult for countries to make a case for access to orphan drugs for rare diseases such as IF.</li>
        <li>Epidemiological data on prevalence and outcomes for patients with IF, such as survival rates on home parenteral nutrition (HPN) and central venous catheter (CVC)-related infection, will aid identification of gaps in healthcare services that need addressing.</li>
        <li>Without an appropriate International Classification of Disease (ICD) code for IF and SBS<sup>6</sup>, it is difficult to generate the outcomes and epidemiology data that are vital to the maintenance of best-practice medical guidance.</li>
      </ul>

      <p class="bold light-green">How can HCPs, patient organisations and policymakers help?<sup>1</sup></p>
      <ul>
        <li>HCPs should engage in data collection and reporting, e.g. by encouraging the set-up of registries for IF.</li>
        <li>HCPs and societies should lead efforts to establish a functioning ICD code for IF.</li>
        <li>Patient organisations are encouraged to advocate for the implementation of a functioning ICD code.</li>
        <li>Policymakers should advocate for the implementation of an ICD code.</li>
      </ul>
    </unmet-need>

    <unmet-need :id="3" :title="'There is an opportunity to improve stakeholder understanding of IF.'">
      <ul>
        <li>Like other rare diseases, a lack of sufficient awareness has a large impact<sup>7</sup> on patients with IF.</li>
        <li>Awareness of IF and options for its treatment among general practitioners and non-specialist clinicians is low, delaying diagnosis.</li>
      </ul>

      <p class="bold light-green">How can HCPs, patient organisations and policymakers help?<sup>1</sup></p>
      <ul>
        <li>HCPs can provide training to non-specialist clinicians and healthcare professionals on managing diagnosis and management of patients with IF.</li>
        <li>Patients are encouraged to establish IF specific patient groups at a national level, facilitated by engaging with regional organisations.</li>
        <li>Policymakers should encourage and fund professional organisations/regulators to provide training.</li>
        <li>Policymakers should encourage and fund the establishment of IF specific patient groups at a national level.</li>
      </ul>
    </unmet-need>

    <unmet-need :id="4" :title="'Current home and social care are not optimal to meet the quality of life needs of those with IF and their families'">
      <ul>
        <li>Factors affecting quality of life include, but are not limited to, frequent bowel movements or stoma bag emptying,<sup>8</sup> impaired sleep and fatigue, psychosocial burdens on families and patients including lack of social activity, depression, body image issues and disrupted relationships, as well as financial constraints through healthcare fees.<sup>9</sup></li>
      </ul>

      <p class="bold light-green">How can HCPs, patient organisations and policymakers help?<sup>1</sup></p>
      <ul>
        <li>HCPs should utilise relevant quality of life measurement tools when assessing IF patients.</li>
        <li>Patients are encouraged to advocate for the use of relevant quality of life measurement tools for IF patient assessments.</li>
        <li>Policymakers should integrate relevant quality of life measurement tools for assessing IF patients.</li>
      </ul>
    </unmet-need>

    <unmet-need :id="5" :title="'There is inadequate research to drive service improvement.'">
      <ul>
        <li>Clinically-focused research is vital to improve our understanding of IF and will ultimately lead to effective new treatment options and improved patient management and care.</li>
        <li>The absence of an International Classification of Disease (ICD) code for IF or SBS<sup>6</sup> makes it difficult to follow statistics and data to support evidence-based changes to policy. As a result, IF has yet to receive the public attention, policy focus or research funding that other organ failures such as kidney and liver failure receive.</li>
      </ul>
      <p class="bold light-green">How can HCPs, patient organisations and policymakers help?<sup>1</sup></p>
      <ul>
        <li>HCPs are encouraged to conduct research, data collection and effective reporting in the field of IF to improve patient outcomes and standard of care.</li>
        <li>Patient voices should be heard in clinical research into IF.</li>
        <li>Policymakers should stimulate the innovative research sector by providing funding and supporting the regulatory incentives framework for research in rare diseases such as IF.</li>
      </ul>
    </unmet-need>

    <unmet-need :id="6" :title="'There is a lack of accredited reference centres.'">
      <ul>
        <li>Management of IF is complex. Treatment may require the coordinated efforts of an multidisciplinary team (MDT) and a comprehensive care plan.<sup>10</sup></li>
        <li>A centralised provider of specialist IF services can make significant improvements in efficiency that directly results in improved patient outcomes.<sup>10</sup></li>
        <li>There is also a need for accredited or recognised centres of excellence to drive patient outcomes.</li>
      </ul>

      <p class="bold light-green">How can HCPs, patient organisations and policymakers help?<sup>1</sup></p>
      <ul>
        <li>HCPs should work in expert multidisciplinary teams with a comprehensive care plan for IF patients.</li>
        <li>HCPs should register centres of excellence on Orphanet.</li>
        <li>Patients can engage with European Patient Advocacy Groups (ePAGs) to increase patient representation and voice in European Reference Networks such as ERN-ERNICA.</li>
        <li>Policymakers should share best practices and facilitate engagement (and funding) in expert reference centres (e.g. Orphanet) and European Reference Networks, such as ERN-ERNICA.</li>
        <li>Policymakers should ensure expert reference centres are registered on Orphanet.</li>
      </ul>
    </unmet-need>

    <atlas-support></atlas-support>
    <atlas-references>
      <ol>
        <li>SCHNEIDER, S. ET AL. (2017). ATLAS – Transforming the vision – Giving chronic intestinal failure patients the care they need – a call to action. Shire International.</li>
        <li>SEGHNP. (2020). Sociedad Española de Gastroenterología Pediatrica. Viewed 5 May 2020. <a href="https://www.seghnp.org/documentos?field_tipo_de_documento_target_id_1=11" target="_blank" @click="externalURL" rel="nofollow">https://www.seghnp.org/documentos?field_tipo_de_documento_target_id_1=11</a>.</li>
        <li>BRITISH INTESTINAL FAILURE ALLIANCE. (2018). Use of peptide growth factors for adult patients with intestinal failure. British Association for Parenteral and Enteral Nutrition. Viewed 30 April 2020. <a href="https://www.bapen.org.uk/pdfs/bifa/position-statements/use-of-peptide-growth-factors-for-adult-patients-with-intestinal-failure-draft.pdf" target="_blank" @click="externalURL" rel="nofollow">https://www.bapen.org.uk/pdfs/bifa/position-statements/use-of-peptide-growth-factors-for-adult-patients-with-intestinal-failure-draft.pdf</a>.</li>
        <li>GPGE. (2018). Gesellschaft für Pädiatrische Gastroenterologie und Ernährung. Positionspapier Teduglutid, Gesellschaft für Pädiatrische Gastroenterologie und Ernährung. Viewed 29 April 2020. <a href="https://static1.squarespace.com/static/55db8048e4b089b9924893b3/t/5aa7d0f1c83025af3fd1a8df/1520947443695/1803_Positionspapier_GPGE_Teduglutid_Konsens.pdf" target="_blank" @click="externalURL" rel="nofollow">https://static1.squarespace.com/static/55db8048e4b089b9924893b3/t/5aa7d0f1c83025af3fd1a8df/1520947443695/1803_Positionspapier_GPGE_Teduglutid_Konsens.pdf</a>.</li>
        <li>ELLEGARD, L ET AL. (2016). Tarmsvikt – Handläggning och behandling, Svensk Gastroenterologisk Förening. Viewed 30 April 2020. <a href="https://svenskgastroenterologi.se/wp-content/uploads/2017/06/2016-Tarmsvikt.pdf" target="_blank" @click="externalURL" rel="nofollow">https://svenskgastroenterologi.se/wp-content/uploads/2017/06/2016-Tarmsvikt.pdf</a>.</li>
        <li>ICD. (2017). International Classification of Diseases (ICD). ICD-10 Version. Viewed 30 April 2020. <a href="http://apps.who.int/classifications/icd10/browse/2010/en#/XI" target="_blank" @click="externalURL" rel="nofollow">http://apps.who.int/classifications/icd10/browse/2010/en#/XI</a>.</li>
        <li>SCHIEPPATI, A. ET AL. (2008). Why rare diseases are an important medical and social issue. Lancet. 371(9629). 2039–2041.</li>
        <li>JEPPESEN, P. (2013). Short bowel syndrome – characterization of an orphan condition with many phenotypes. Expert Opinion on Orphan Drugs. 1(7). 569–580.</li>
        <li>WINKLER, M. ET AL. (2014). Clinical, social, and economic impacts of home parenteral nutrition dependence in short bowel syndrome. Journal of Parenteral and Enteral Nutrition. 38(1 Suppl), 32S–37S.</li>
        <li>PIRONI, L. ET AL. (2016). ESPEN guidelines on chronic intestinal failure in adults. Clinical Nutrition. 35. 247-307.</li>
      </ol>
    </atlas-references>
  </div>
</template>

<script>
import AtlasSupport from '@/components/Support'
import UnmetNeed from '@/components/UnmetNeed'
import AtlasReferences from '@/components/References'
import externalURL from '@/external-url'

export default {
  data () {
    return {
      title: 'Unmet needs',
      description: 'What are the unmet needs regarding intestinal failure? ATLAS has identified six key areas to be addressed to help patients',
      keywords: 'Unmet Needs, Patients, ATLAS.eu, Intestinal Failure, Digestive System, Inflammatory Bowel Disease, Crohn’s Disease, Irritable bowel syndrome, ibs symptoms'
    }
  },
  methods: {
    externalURL
  },
  components: {
    AtlasSupport,
    AtlasReferences,
    UnmetNeed
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
