<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <h2>Upcoming events</h2>
    <p>You can check back here at any time to see any upcoming events for the ATLAS Programme.</p>
    <h2>Previous events</h2>
    <div class="event">
      <h3>ESPEN 2020</h3>
      <p>ATLAS was present at the 2020 ESPEN Virtual Congress, which took place from 19-21 September 2020.</p>
      <p>The event marked the first time that ATLAS had been present at a major scientific Congress. Delegates were able to access a virtual booth which gave them access to resources about ATLAS and intestinal failure.</p>
      <p>We’re delighted to say that over 1,000 attendees visited our booth!</p>
      <img alt="ESPEN 2020" srcset="/images/pages/events/espen@2x.jpg 2x, /images/pages/events/espen.jpg 1x" src="/images/pages/events/espen@2x.jpg"/>
    </div>
    <div class="event">
      <h3>ATLAS Parliamentary Launch Event</h3>
      <p>ATLAS <a @click="externalURL" href="https://www.theparliamentmagazine.eu/news/article/improving-standards-of-care-in-chronic-intestinal-failure" title="_blank" rel="nofollow">held an event in the European Parliament</a> on Wednesday 28 March 2018, hosted by Cristian Buşoi MEP (Romania) and the Parliament Magazine.</p>
      <p>The event was important as it allowed ATLAS to highlight the issue of intestinal failure (IF) and the unmet needs of people living with IF to figures within the healthcare, rare disease and policy communities.</p>
      <p>The event featured speeches from patients, leading gastrointestinal healthcare professionals, rare disease experts and EU policymakers. The event also featured a presentation of the ATLAS Policy Paper, originally developed in 2017.</p>
      <img style="width: 100%" alt="ATLAS Parliamentary Launch Event" srcset="/images/pages/events/espen@2x.jpg 2x, /images/pages/events/launch.jpg 1x" src="/images/pages/events/launch@2x.jpg"/>
      <p>Left to right: Palle Jeppesen, Simon Lal, José Inácio Faria MEP, Marek Lichota, Cristian Busoi MEP, Stéphane Schneider</p>
      <atlas-quote :text="'If we did the research we would have the evidence; we need to up our game. Collaboration is the future. We need centres of excellence and multicentre international studies. The EU is perfect for reaching the people to join these activities; the ATLAS Programme is part of this.'" :author="'Palle Jeppesen, ATLAS launch event'"></atlas-quote>
      <p></p>
    </div>
    <atlas-support></atlas-support>
    </div>
</template>

<script>
import AtlasSupport from '@/components/Support'
import AtlasQuote from '@/components/Quote'
import externalURL from '@/external-url'

export default {
  data () {
    return {
      title: 'Events',
      description: 'ATLAS updates relating to large or small intestine failure.',
      keywords: 'Events, ATLAS.eu, human digestive system'
    }
  },
  components: {
    AtlasSupport,
    AtlasQuote
  },
  methods: {
    externalURL
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>

<style lang="scss">
  .event {
    padding: 25px;
    background: $white;
    font-size: 20px;
    line-height: 30px;
    margin: 25px 0;

    h3 {
      color: $lightGreen;
      font-weight: bold;
      letter-spacing: 0.043em;
      margin-bottom: 25px;
    }

    p {
      letter-spacing: 0.034em;
    }

    img {
      margin-bottom: 0;
    }

    img + p {
      margin-top: 15px;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
</style>
