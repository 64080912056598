<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>The page you requested does not exist or has been moved! If you typed the URL, please make sure it is correct and try again.</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: 'Page Not Found'
    }
  },
  metaInfo () {
    return {
      title: this.title,
      titleTemplate: null
    }
  }
}
</script>
