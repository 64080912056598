export default {
  pop: {
    value: '38,104,833',
    reference: {
      text: 'The Global Cancer Observatory (2018), Poland, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/616-poland-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '38.324',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 68.97,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 23.05,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '0.4',
    reference: {
      text: 'JEPPESEN, P. (2014). Spectrum of Short Bowel Syndrome in Adults: Intestinal Insufficiency to Intestinal Failure. Journal of Parenteral and Enteral Nutrition. 38(1). 8S–13S.\n' +
        'KELLY, DG. et al. (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.',
      link: null
    }
  },
  kidney: {
    value: '11,292',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '14,527',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: 1,
    reference: {
      text: 'STAUN, M. et al. (2007). Management of intestinal failure in Europe. A questionnaire-based study on the incidence and management. Dynamic Medicine. 6(1).',
      link: null
    }
  },
  research_liver: {
    value: 2,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 83,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 5,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Apetyt na Życie (Appetite for Life)',
      type: '<abbr title="Home Artificial Nutrition">HAN</abbr>',
      reference: {
        text: 'Apetyt na Życie. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://apetytnazycie.org/en/homepage-eng/'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'MORENO, J. et al. (2001). Survey on legislation and funding of Home Artificial Nutrition in Different European Countries. Clinical Nutrition 20(2). 117-123.',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
