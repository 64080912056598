export default {
  pop: {
    value: '65,233,279',
    reference: {
      text: 'The Global Cancer Observatory (2018), France, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/250-france-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '314.079',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 77.09,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 9.38,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '3.6',
    reference: {
      text: 'KELLY, DG. et al (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37. 6. Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  kidney: {
    value: '9,249',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '11,979',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: 18,
    reference: {
      text: 'Ministère des solidarités et de la santé (2020). Note d’information n° DGOS/PF2/2020/22. Viewed 25 January 2020.',
      link: 'https://solidarites-sante.gouv.fr/fichiers/bo/2020/20-02/ste_20200002_0000_0025.pdf'
    }
  },
  research_liver: {
    value: 18,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 186,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 10,
    reference: null
  },
  patient_orgs: [
    {
      name: 'La Vie Par Un Fil',
      type: '<abbr title="Home Artificial Nutrition">HAN</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'La Vie par un Fil. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.lavieparunfil.com'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'MORENO, J. et al. (2001). Survey on legislation and funding of Home Artificial Nutrition in Different European Countries. Clinical Nutrition 20(2). 117-123.',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
