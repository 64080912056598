<template>
  <div class="quote">
    <span class="bold">{{ text }}<sup v-if="reference">{{ reference }}</sup></span><span class="light-green" v-if="author !== ''"> - {{ author }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    reference: {
      required: false,
      default: ''
    },
    author: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss">
  .quote {
    background: $darkBlue;
    border-radius: 20%/55%;
    margin: 75px 0 35px;
    padding: 35px 50px;
    font-size: 25px;
    line-height: 31px;
    color: $white;
    position: relative;
    text-align: center;

    &:before,
    &:after {
      position: absolute;
      display: block;
      content: '';
      width: 59px;
      height: 43px;
      background: url('/images/quote.svg') no-repeat;
    }

    &:before {
      left: 65px;
      top: -20px;
    }

    &:after {
      transform: rotate(180deg);
      right: 65px;
      bottom: -20px;
    }
  }
</style>
