export default {
  0: {
    hover: 'blue',
    name: 'Luisa Avedano',
    location: 'Brussels, Belgium',
    title: 'CEO, European Federation of Crohn’s and Ulcerative Colitis Associations (EFCCA)',
    bio: 'Luisa knew that her purpose lied with patient advocacy when during one of her training sessions with an IBD youth association, she met a young patient whose resilience inspired her. She stayed in contact with him for several years, supporting him as much as she could. His journey led him to eventually become an EFCCA Chairman where he asked her to join as CEO, to which she hurriedly accepted and begun a journey with EFCCA akin to a second family. Luisa has a vision of patients with different diseases uniting under the banner of SBS or IF to help drive a clearer understanding of the condition and secure equal treatment for all patients worldwide, including much needed psychological services for children and young people. The ATLAS Programme is an opportunity for her to voice the needs of patients and her members. Her favourite element of the programme is that it challenges doctors and experts, who are rarely used to considering patients as their peers, to rethink how they approach their practice.'
  },
  1: {
    hover: 'green',
    name: 'Professor Palle Jeppesen',
    location: 'Rigshospitalet, Copenhagen, Denmark',
    title: 'Gastroenterologist and Professor of Gastroenterology',
    bio: 'Professor Jeppesen believes that patients should have the same rights regarding access to treatment in experienced centres as patients with other organ failures. During Palle’s early research years in Denmark he was supervised by Karin Ladefoged, one of the pioneers of intestinal failure (IF) treatment, which led him to progress his ambitions as a young researcher under Per Brøbech at Rigshospitalet. Upon personally meeting IF patients and learning of the clinical neglect that they suffered when trying to improve the prognosis of their condition, he decided to make it his calling to care for IF patients. He joined the ATLAS Programme with a shared passion for its vision to allow better access to treatments and healthcare for all IF patients, saving thousands of lives around the globe. His experience and ambitions for IF patients align well with ambitions of the ATLAS programme, allowing for his ideas to be exchanged in an open-minded forum and held accountable through a shared commitment to patients and other members.'
  },
  2: {
    hover: 'blue',
    name: 'Professor Francisca Joly',
    location: 'Hospital Beaujon AP-HP, Paris, France',
    title: 'Gastroenterologist and Professor of Clinical Nutrition',
    bio: 'Professor Joly wants a world where the voices of intestinal failure (IF) patients become audible in care, education, training and research. A gastroenterologist by training, Francisca became involved with ATLAS as she wanted to share the experiences of her expert centre and to better understand what is happening in other centres and countries. She wants to see a world where any IF patient has access to an expert centre and where such centres and patient partners take a central place in all steps. Joly really enjoys meeting the other experts, policymakers and patients at each ATLAS meeting and the programme allows her to believe in a better future for IF patients.'
  },
  3: {
    hover: 'blue',
    name: 'Professor Stanislaw Klek',
    location: 'Stanley Dudrick’s Memorial Hospital, Skawina, Poland',
    title: 'Surgeon and Professor of Oncology and Clinical Nutrition',
    bio: 'After seeing home parenteral nutrition (HPN) transform the lives of patients with chronic intestinal failure (IF), Professor Klek decided to work full-time on the care of these patients. He was moved by the effectiveness of HPN in speeding their recovery after major resections with an ultrashort bowel, allowing some to return to work or school and, more importantly, to their families. To him, the ATLAS Programme represents an opportunity to change what people think about IF for the better. He loves the international work on the committee as this allows him to learn more about how IF is treated worldwide, and thereby use this acquired knowledge to improve treatment in his home country of Poland.'
  },
  4: {
    hover: 'green',
    name: 'Professor Simon Lal',
    location: 'Salford Royal NHS Foundation Trust, Salford, UK',
    title: 'Gastroenterologist and Professor of Gastroenterology',
    bio: 'After working with patients experiencing very complex gastrointestinal conditions, Professor Lal was greatly influenced by the experiences he had with the multi-disciplinary team that he worked alongside to care for intestinal failure (IF) patients, inspiring him to specialise in IF. His motivation for being part of the ATLAS Programme was to join the fight to improve the limited expertise and care available to IF patients, particularly in developing regions and countries. Simon’s input to launch ATLAS at the European Parliament by engaging Members of European Parliament (MEPs) and beginning to map IF services across Europe marks the beginning of an ongoing commitment to countries all over the world.'
  },
  5: {
    hover: 'blue',
    name: 'Professor Miguel Leon-Sanz',
    location: 'University Hospital Doce de Octubre, Madrid, Spain',
    title: 'Endocrinologist and Professor of Endocrinology',
    bio: 'To Professor Leon-Sanz, the ATLAS Programme represents an opportunity for him to exchange experiences and opinions with other intestinal failure (IF) experts in the field, whether that is physicians, patients or industry leaders, to improve the lives of IF patients everywhere. After working in artificial nutrition in close cooperation with surgeons and gastroenterologists, he became involved in the medical treatment of IF patients. Since then he has aspired to bring greater social awareness to the demands of life-saving home parenteral nutrition that impact patient autonomy, and improve access to other short-term and longer-term therapies that aid in management of IF.'
  },
  6: {
    hover: 'green',
    name: 'Marek Lichota',
    location: 'Krakow, Poland',
    title: 'CEO, Appetite for Life and IF patient',
    bio: 'As an intestinal failure (IF) patient who has experienced the weight of the daily challenges that the condition brings, Marek was driven to turn his struggle into something positive. His position on the ATLAS Programme gives him the platform to bring the patient perspective to a committee of mostly experienced IF clinicians and healthcare policymakers, actively influencing the improvement of health outcomes for others with chronic IF. He aspires to use the committee to create and implement a vast model of complex, coordinated IF patient care which will make treatment equally available to all.\n'
  },
  7: {
    hover: 'green',
    name: 'Professor Greger Lindberg',
    location: 'Karolinska University Hospital, Stockholm, Sweden',
    title: 'Gastroenterologist and Professor of Medical Gastroenterology and Hepatology',
    bio: 'As a neurogastroenterologist with decades of experience working with intestinal failure (IF) patients, Professor Lindberg has hopes for patients to receive equality of access to the best standard of care across different healthcare systems around the world. This aspiration brought him to the ATLAS Programme, where he continues to push for the deserving recognition of IF by both healthcare professionals and wider society. He finds the role of patients as policy influencers particularly important in this vision.'
  },
  8: {
    hover: 'green',
    name: 'Professor Loris Pironi',
    location: 'St. Orsola-Malpighia Hospital, Bologna, Italy',
    title: 'Gastroenterologist and Professor of Clinical Nutrition',
    bio: 'Gastrointestinal care and nutrition has been a central interest of Professor Pironi since his influential time in postgraduate education. The ATLAS Programme allows Loris to pursue his desire to raise awareness of intestinal failure (IF) worldwide by getting access to expert doctors, patients, companies and media experts; debating and comparing opinions that can ultimately inform impactful communications strategies. His current ambitions are to lobby for SBS and IF to become recognised conditions in the ICD code, and using this notoriety to recognise expert IF centres across national healthcare systems.'
  },
  9: {
    hover: 'green',
    name: 'Professor Stéphane Schneider',
    location: 'Chair of ATLAS Steering Committee Archet Hospital, Nice, France',
    title: 'Gastroenterologist and Professor of Clinical Nutrition',
    bio: 'It was during his training in a home parenteral nutrition (HPN) reference centre that Professor Schneider first met patients with intestinal failure (IF). Stéphane was moved by the lack of consideration for patients, and wanted to fight the injustice of the condition being treated poorly compared to other organ failures. He finds the ATLAS Programme a way to realise ambitions for better patient care, and wants to see a future where all IF patients are referred to expert centres by knowledgeable healthcare professionals, with equal access to reference and novel treatments.\n'
  },
  10: {
    hover: 'blue',
    name: 'Carolyn Wheatley',
    location: 'Christchurch, UK',
    title: 'Chair, PINNT and IF patient',
    bio: 'Carolyn’s involvement in ATLAS happened by accident; helping people and sharing opinions at the start of her personal journey as a short bowel syndrome with intestinal failure (SBS) patient was not defined with a title. She learned her expertise the hard way. It was natural to her to help others and contribute to services and products, and with natural progression this grew into becoming an advocate, and joining the ATLAS programme. Seeing her actions make such a difference, she could not stop. There is never an end to raising awareness of the condition and how services for patients are underfunded and under resourced. Carolyn envisions a world where there is no division for the treatment and support of SBS based on where patients live, how well services are funded or if they are affordable. She wants to create a network of support so people with SBS can travel freely, gain local support in the event of an emergency and be confident that the care and support they need is second to none.\n'
  }
}
