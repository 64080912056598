<template>
  <div :class="{ 'references-block': true, collapsed: !open }">
    <button type="button" @click="open = !open">References <img src="/images/arrow.svg" class="toggle" :alt="open ? 'Hide references' : 'Show references'" /></button>
    <div class="references-wrapper">
      <div class="references">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  }
}
</script>

<style lang="scss">
  .references-block {

    button {
      display: inline-block;
      padding: 15px;
      outline: none;
      border: 0 none;
      background: $darkBlue;
      font-family: $font;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      color: $white;
      vertical-align: middle;
      cursor: pointer;
    }

    .toggle {
      display: inline-block;
      vertical-align: middle;
      width: 15px;
      height: 15px;
      margin: -3px 0 0 5px;
      transition-duration: 0.3s;
      transition-property: transform;
    }

    .references-wrapper {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:after {
        content: '';
        height: 1px;
        transition: height 0.5s linear, max-height 0s 0.5s linear;
        max-height: 0;
      }

      .references {
        transition: margin-bottom 0.3s cubic-bezier(0, 0, 0, 1);
        margin-left: 0;
        margin-bottom: 0;
        max-height: 1000000px;
        background: $white;
        padding: 25px 30px;

        p {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 0;
        }

        ol {
          margin-left: 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        li {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &.collapsed {
      .toggle {
        transform: rotate(90deg);
      }

      .references-wrapper {
        &:after {
          height: 0;
          transition: height 0.5s linear;
          max-height: 1px;
        }

        .references {
          margin-bottom: -2000px;
          transition: margin-bottom 0.5s cubic-bezier(1, 0, 1, 1),
          visibility 0s 0.5s,
          max-height 0s 0.5s;
          visibility: hidden;
          max-height: 0;
        }
      }
    }
  }
</style>
