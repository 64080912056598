<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>Professor Simon Lal of the <router-link :to="{ name: 'Committee' }">ATLAS Steering Committee</router-link> was interviewed by Professor Stéphane Schneider, the Committee Chairman. In the interview, Professor Lal discusses SBS, what it’s like for patients to live with the condition and why it’s important to achieve the best possible care.</p>
    <p>Watch Professor Lal’s interview:</p>
    <embed-video :src="'https://www.youtube.com/embed/XJ3BS8Cs2qY'"></embed-video>

    <h2>Definition, causes and symptoms</h2>
    <collapsible :title="'Intestinal failure (IF) is the rarest form of organ failure<sup>1</sup> and yet it does not have an ICD code.'" :reference="'2'" :blue="true">
      <ul>
      <li>As per the European Society for Clinical Nutrition and Metabolism (ESPEN) definition, IF is the reduction of
      gut function, below the minimum necessary for the absorption of macronutrients and/or water and
        electrolytes, such that intravenous supplementation is required to maintain health and/or growth.<sup>1</sup></li>
        <li>ESPEN classifications of intestinal failure:<sup>1</sup>
          <ol class="type">
            <li>Acute, short-term; usually self-limiting</li>
            <li>Prolonged acute condition; requires complex multidisciplinary care and intravenous supplementation over a period of weeks or months</li>
            <li>Chronic condition; requires intravenous supplementation over months or years; may or may not be reversible</li>
          </ol>
      </li>
      </ul>
      <p>You can find the <a href="https://www.espen.org/guidelines-home/espen-guidelines" target="_blank" @click="externalURL" rel="nofollow">ESPEN guidelines here</a>.</p>
      <atlas-quote :text="'[Intestinal failure] management requires complex technologies and multidisciplinary and multiprofessional activity and expertise dedicated to the care of both the underlying gastrointestinal condition and the home parenteral nutrition (HPN) support'" :reference="1"></atlas-quote>
    </collapsible>
    <collapsible :title="'There is a wide range of causes for IF, all of which prevent the body from absorbing vital fluids and nutrients needed for survival.'" :reference="'3'">
      <ul>
        <li>IF can be classified according to five major conditions that originate from various gastrointestinal or systemic diseases. These are short bowel syndrome (SBS), intestinal fistula, intestinal dysmotility, mechanical obstruction and extensive small bowel mucosal disease.<sup>1</sup></li>
        <li>The leading cause of IF is short bowel syndrome (SBS). SBS results from loss of intestinal absorptive capacity due to underlying anatomic (extensive surgical resection) or functional (congenital, loss of functional tissue) causes.<sup>3,4</sup></li>
      </ul>
    </collapsible>
    <collapsible :title="'There are major consequences linked with IF through SBS.'" :blue="true">
      <ul>
        <li>These include gastrointestinal complications, liver failure, catheter-related complications and infections, metabolic complications, gallstones, renal failure, weight loss, fatigue, premature ageing, irritability, depression, increased susceptibility to other diseases and a high burden of morbidity and mortality.<sup>5,6,7</sup></li>
      </ul>
    </collapsible>

    <h2 style="margin-top: 60px">The impact of IF on patients, carers, loved ones and society</h2>
    <p class="bold">People living with IF need parenteral support (PN/IV) as it provides the necessary nutritional and fluid requirements to maintain adequate health and/or growth.<sup>1</sup></p>
    <ul>
      <li>Home parenteral nutrition (HPN), a form of parenteral support, is a life-sustaining therapy that prevents death from malnutrition or dehydration, but it does not cure the underlying condition.<sup>8</sup></li>
    </ul>

    <p class="bold">Although HPN provides vital, life-sustaining fluid and nutrient support for patients with IF<sup>8</sup>, this therapeutic approach is associated with potentially serious or life-threatening complications.<sup>9,10</sup></p>
    <ul>
      <li>Patients with SBS and their families are severely impacted by the condition.<sup>11</sup></li>
      <li>SBS impacts all aspects of patients’ lives, and impact is not just linked to the need for PN.<sup>11</sup></li>
      <li>Support from the healthcare team is key to helping patients adapt their lives around their disease and treatment, and learning to view this with acceptance.<sup>11</sup></li>
    </ul>

    <p class="bold">Treating IF poses a significant financial cost for healthcare providers who increasingly lack resources.<sup>8,12,13</sup></p>
    <ul>
      <li>IF is associated with significant healthcare costs in adults and children, particularly owing to the high costs of hospitalisation and HPN.<sup>14</sup></li>
    </ul>

    <atlas-support></atlas-support>
    <atlas-references>
      <ol>
        <li>PIRONI, L. ET AL. (2016). ESPEN guidelines on chronic intestinal failure in adults. Clinical Nutrition. 35. 247-307.</li>
        <li>ICD. (2017). International Classification of Diseases (ICD). ICD-10 Version. Viewed 30 April 2020. <a href="http://apps.who.int/classifications/icd10/browse/2010/en#/XI" target="_blank" @click="externalURL" rel="nofollow">http://apps.who.int/classifications/icd10/browse/2010/en#/XI</a>.</li>
        <li>PIRONI, L. ET AL. (2015). ESPEN endorsed recommendations. Definition and classification of intestinal failure in adults. Clinical Nutrition. 34. 171–180.</li>
        <li>BUCHMAN, A. ET AL. (2003). AGA technical review on short bowel syndrome and intestinal transplantation. Gastroenterology. 124(4). 1111–1134.</li>
        <li>PIRONI, L. ET AL. (2016). Definitions of Intestinal Failure and the Short Bowel Syndrome. Best Practice & Research Clinical Gastroenterology. 30(2). 173–185.</li>
        <li>HOFSTETTER, S. ET AL. (2013). Key issues in addressing the clinical and humanistic burden of short bowel syndrome in the US. Current Medical Research and Opinion. 29(5). 495–504.</li>
        <li>NIGHTINGALE, JMD. ED. (2001). Intestinal Failure. Greenwich Medical Media. 177–198.</li>
        <li>WINKLER, M. ET AL. (2014). Clinical, social, and economic impacts of home parenteral nutrition dependence in short bowel syndrome. Journal of Parenteral and Enteral Nutrition. 38(1 Suppl), 32S–37S.</li>
        <li>DIBB, M. ET AL. (2017). Survival and nutritional dependence on home parenteral nutrition : Three decades of experience from a single referral centre. Clinical Nutrition. 36(2). 570–576.</li>
        <li>WU, G. ET AL. (2017). Prevalence and risk factors for complications in adult patients with short bowel syndrome receiving long-term home parenteral nutrition. Asia Pacific Journal of Clinical Nutrition. 26(4). 591–597.</li>
        <li>SOWERBUTTS, AM. ET AL. (2020). Short bowel syndrome and the impact on patients and their families: a qualitative study. Journal of Human Nutrition and Dietetics. Online ahead of print.</li>
        <li>NHS. (2010). Specialised services national definitions set – Home Parenteral Nutrition- 2nd Edition. NHS</li>
        <li>ROSKOTT, A. ET AL. (2015). Cost-effectiveness of intestinal transplantation for adult patients with intestinal failure: A simulation study. American Journal of Clinical Nutrition. 101(1). 79–86.</li>
        <li>SPENCER, A. ET AL. (2008). Pediatric short-bowel syndrome: The cost of comprehensive care. American Journal of Clinical Nutrition. 88(6). 1552–9.</li>
      </ol>
    </atlas-references>
  </div>
</template>

<script>
import AtlasSupport from '@/components/Support'
import AtlasQuote from '@/components/Quote'
import Collapsible from '@/components/Collapsible'
import EmbedVideo from '@/components/EmbedVideo'
import AtlasReferences from '@/components/References'
import externalURL from '@/external-url'

export default {
  data () {
    return {
      title: 'What is intestinal failure (IF)?',
      description: 'Intestinal failure (IF) is the rarest form of digestive system organ failure and yet it does not have an ICD code.',
      keywords: 'Intestinal Failure, Digestive System, Organ Failure, ICD Code, ibs symptoms'
    }
  },
  methods: {
    externalURL
  },
  components: {
    EmbedVideo,
    Collapsible,
    AtlasSupport,
    AtlasQuote,
    AtlasReferences
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
