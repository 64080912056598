export default {
  pop: {
    value: '5,542,526',
    reference: {
      text: 'The Global Cancer Observatory (2018), Finland, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/246-finland-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '25.512',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 76.74,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 20.23,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '10,213',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '12,833',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: 1,
    reference: {
      text: 'Helsinki University Hospital (2020), Pediatric Surgery, Helsinki University Hospital, viewed 21 August 2020',
      link: 'https://www.hus.fi/en/about-us/patient-care-quality-and-patient-safety/abdominal-center'
    }
  },
  research_liver: {
    value: 0,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 17,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 3,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Crohn ja Colitis ry',
      type: '<abbr title="Inflammatory Bowel Disease">IBD</abbr>, <abbr title="Gastrointestinal">GI</abbr>',
      reference: {
        text: 'Crohn ja Colitis. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://crohnjacolitis.fi'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'POHJU, A. et al. (2019). Fragmented management of long-term parenteral support for adult intestinal failure in Finland. Scandinavian Journal Of Gastroenterology. 54(4). 414-418.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: null,
    reference: null
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
