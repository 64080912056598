export default {
  pop: {
    value: '4,803,743',
    reference: {
      text: 'The Global Cancer Observatory (2018), Ireland, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/372-ireland-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '27.428',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 73.29,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 12.28,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '9.6',
    reference: {
      text: 'BELL, A. et al. (2018). Point Prevalence of Adult Intestinal Failure in Republic of Ireland. Irish Medical Journal. 111(2). 688.',
      link: null
    }
  },
  kidney: {
    value: '8,117',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '14,137',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: true,
    reference: {
      text: 'RICE, N. et al. (2013). A Review of Home Parenteral Nutrition in Ireland: Recommendations for Action. IrSPEN Special Report. 1. 5-39.',
      link: null
    }
  },
  ref_centers: {
    value: null,
    reference: null
  },
  research_liver: {
    value: 0,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 5,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [],
  hpn_available: {
    value: true,
    reference: {
      text: 'RICE, N. et al. (2013). A Review of Home Parenteral Nutrition in Ireland: Recommendations for Action. IrSPEN Special Report. 1. 5-39.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'RICE, N. et al. (2013). A Review of Home Parenteral Nutrition in Ireland: Recommendations for Action. IrSPEN Special Report. 1. 5-39.',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
