export default {
  pop: {
    value: '1,306,790',
    reference: {
      text: 'The Global Cancer Observatory (2018), Estonia, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/233-estonia-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '1.9',
    reference: {
      text: 'Tervise Arengu Instituut (2019), Tervishoiukulud 2018, Tervise Arengu Instituut, viewed 21 August 2020, EUR 1.74 billion = value in the reference (exchange rate 29/04/2020)',
      link: 'https://www.tai.ee/et/terviseandmed/tervisestatistika-ja-uuringute-andmebaas/uuendused/4602-tervishoiukulud-2018'
    }
  },
  gov_exp: {
    value: 74.66,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 23.66,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '19,692',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) 2017, GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '16,357',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) 2017, GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: null,
    reference: null
  },
  research_liver: {
    value: 1,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 6,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Eesti Põletikulise Soolehaiguse Selts MTÜ',
      type: '<abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Eesti Põletikulise Soolehaiguse Selts. 2020. Homepage. Viewed 21 August 2020',
        link: 'http://www.ibd.ee'
      }
    }
  ],
  hpn_available: {
    value: null,
    reference: null
  },
  hpn_reimbursed: {
    value: null,
    reference: null
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
