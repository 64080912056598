<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p><span class="bold red">Address</span><br />
    Takeda Pharmaceuticals International AG<br />
    Thurgauerstrasse 130<br />
    8152 Glattpark-Opfikon<br />
    Zurich, Switzerland</p>

    <p><span class="bold red">Contact</span><br />
    Tel. <a href="tel:+41554515200">+41 55 451 52 00</a><br />
    Fax <a href="tel:+41554515220">+41 55 451 52 20</a><br />
    E-mail <a href="mailto:info@takeda.ch">info@takeda.ch</a></p>

    <p><span class="bold red">Medical information</span><br />
    Tel. <a href="tel:+41412884096">+41 41 288 40 96</a><br />
    E-mail <a href="mailto:medinfoEMEA@takeda.com">medinfoEMEA@takeda.com</a>
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: 'Contact us',
      description: 'How can I find out more about intestinal failure?  If you are interested in contacting ATLAS, please feel free to email our team of healthcare experts.',
      keywords: 'ATLAS, Intestinal Failure Help, Intestinal Failure Support, Intestinal Failure Patient, Intestinal Failure Specialist near, Healthcare'
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  },
  mounted () {
    document.body.classList.add('takeda')
  },
  destroyed () {
    document.body.classList.remove('takeda')
    // Second time in case this page was loaded as a pre-rendered page
    document.body.classList.remove('takeda')
  }
}
</script>
