<template>
  <div :class="{ member: true, expanded: expanded, green: person.hover === 'green', blue: person.hover === 'blue' }" @click="selectMember(id)">
    <div class="toggle"></div>
    <div class="image">
      <img :src="'/images/pages/committee/' + image + '@2x.png'" :srcset="'/images/pages/committee/' + image + '@2x.png 2x, /images/pages/committee/' + image + '.png 1x'" :alt="person.name" />
    </div>
    <div class="text">
      <h4>{{ person.name }}</h4>
      <div class="location">{{ person.location }}</div>
      <div class="title">{{ person.title }}</div>
    </div>
    <div class="bio">
      {{ person.bio }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    expanded () {
      return this.$store.state.selectedMember !== null && this.id === this.$store.state.selectedMember
    },
    image () {
      return this.person.name.toLowerCase().replaceAll(' ', '_').replaceAll('é', 'e')
    }
  },
  methods: {
    selectMember (id) {
      this.$store.dispatch('selectMember', this.expanded ? null : id)
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    person: {
      type: Object,
      required: true
    }
  },
  watch: {
    $route () {
      this.$store.dispatch('selectMember', null)
    }
  }
}
</script>
