<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>Based on the <router-link :to="{ name: 'Needs' }">unmet needs</router-link> set forth by the <router-link :to="{ name: 'Committee' }">ATLAS Steering Committee</router-link>, the lack of reference centers is a
      significant unmet need in the IF community, as shown by the variation  in location and numbers on the map below. The management of IF is complex, often requires a
      multidisciplinary approach and a comprehensive care plan. Accredited or recognised centres of
      excellence would ensure that all IF patients have access to optimal care, no matter where they live.</p>

      <p>Therefore, we urge HCPs, patient organisations and policymakers to work together to register centres of
      excellence on Orphanet, ensuring that countries share best practice and facilitate engagement with one
      another.</p>
    <a href="/files/reference-centres.pdf" class="download" target="_blank" title="Download the reference centre map">
      <img srcset="/images/pages/reference-centres@2x.jpg 2x, /images/pages/reference-centres.jpg 1x" src="/images/pages/reference-centres.jpg" :alt="title" />
      <span>Download as PDF</span>
    </a>

    <p>Data collection and validation took place from October 2019 to April 2020. The references mentioned
      include peer-reviewed academic sources, official Governmental or national/regional authority sources,
      and reputable online sources that are publicly accessible.</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: 'Intestinal Failure Reference Centres across Europe',
      description: 'The lack of reference centers is a significant unmet need in the IF community, as shown by the variation in location and numbers.',
      keywords: ''
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>
