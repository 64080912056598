export default {
  pop: {
    value: '2,081,259',
    reference: {
      text: 'The Global Cancer Observatory (2018), Slovenia, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/705-slovenia-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '4.423',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 71.82,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 12.33,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '12,883',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '18,997',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: 3,
    reference: {
      text: 'Ljubljana Institute of Oncology, Division of Surgery, Fields of work, viewed 21 August 2020, https://www.onko-i.si/eng/sectors/medical_care_sector/division_of_surgery\n' +
        'European Reference Network, MetabERN, University Medical Centre Ljubljana, viewed 21 August 2020, https://metab.ern-net.eu/university-medical-centre-ljubljana/',
      link: null
    }
  },
  research_liver: {
    value: 1,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 8,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [
    {
      name: 'KVČB',
      type: '<abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Društvo za kronično vnetno črevesno bolezen. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.kvcb.si/'
      }
    },
    {
      name: 'Invalidsko društvo Kengurujček Slovenije',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Chronic Intestinal Failure">CIF</abbr>',
      reference: {
        text: 'Invalidsko društvo Kengurujček Slovenije. 2017. Homepage. Viewed 21 August 2020',
        link: 'http://www.kengurujcek.si'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'Zavod za zdravstveno zavarovanje Slovenije (ZZZS) (2008), Elektronska Gradiva, ZZZS, viewed 21 August 2020',
      link: 'https://www.zzzs.si/zzzs/info/egradiva.nsf/'
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
