<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>ATLAS has developed three infographics to outline the landscape for intestinal failure (IF) and the work of the ATLAS Programme.</p>
    <p>Each infographic is tailored to a specific audience, namely healthcare professionals (HCPs), patients and policymakers respectively. These infographics give an easily digestible, informative overview of IF and ATLAS for each key audience, meaning that it is easy to understand the burden that patients and loved ones go through.</p>
    <p>Download the infographics:</p>
    <div class="info-graphics">
      <div class="vertical-stretch">
        <div class="info-graphic">
          <h2>For HCPs</h2>
          <span class="sub-title">Available in English</span>
          <div class="image">
            <img srcset="/images/pages/infographics/hcp@2x.jpg 2x, /images/pages/infographics/hcp.jpg 1x" src="/images/pages/infographics/hcp.jpg" alt="Infographics for HCPs" />
          </div>
          <div>
            <a target="_blank" href="/files/infographics/en/infographic-hpc.pdf" class="button" title="English">Download</a>
          </div>
        </div>
        <div class="info-graphic">
          <h2>For patients</h2>
          <span class="sub-title">Available in 11 languages</span>
          <div class="image">
            <img srcset="/images/pages/infographics/patients@2x.jpg 2x, /images/pages/infographics/patients.jpg 1x" src="/images/pages/infographics/patients.jpg" alt="Infographics for patients" />
          </div>
          <div>
            <h3>Download this infographic in</h3>
            <div class="downloads">
              <a target="_blank" href="/files/infographics/en/infographic-patients.pdf" title="English">English</a>
              <a target="_blank" href="/files/infographics/cz/infographic-patients.pdf" title="Czech">Czech</a>
              <a target="_blank" href="/files/infographics/de/infographic-patients.pdf" title="German">German</a>
              <a target="_blank" href="/files/infographics/es/infographic-patients.pdf" title="Spanish">Spanish</a>
              <a target="_blank" href="/files/infographics/fr/infographic-patients.pdf" title="French">French</a>
              <a target="_blank" href="/files/infographics/hr/infographic-patients.pdf" title="Croatian">Croatian</a>
              <a target="_blank" href="/files/infographics/hu/infographic-patients.pdf" title="Hungarian">Hungarian</a>
              <a target="_blank" href="/files/infographics/it/infographic-patients.pdf" title="Italian">Italian</a>
              <a target="_blank" href="/files/infographics/pl/infographic-patients.pdf" title="Polish">Polish</a>
              <a target="_blank" href="/files/infographics/pt/infographic-patients.pdf" title="Portuguese">Portuguese</a>
              <a target="_blank" href="/files/infographics/ro/infographic-patients.pdf" title="Romanian">Romanian</a>
            </div>
          </div>
        </div>
        <div class="info-graphic">
          <h2>For policymakers</h2>
          <span class="sub-title">Available in English</span>
          <div class="image">
            <img srcset="/images/pages/infographics/policy@2x.jpg 2x, /images/pages/infographics/policy.jpg 1x" src="/images/pages/infographics/policy.jpg" alt="Infographics for policy makers" />
          </div>
          <div>
            <a target="_blank" href="/files/infographics/en/infographic-policymakers.pdf" class="button" title="English">Download</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      title: 'The landscape of IF - infographics',
      description: 'Three intestinal failure infographics to outline the landscape for intestinal failure (IF) and the work of the ATLAS Programme for patient care.',
      keywords: 'Intestinal Failure, Patient Care, Healthcare Professionals, ibs symptoms, Inflammatory Bowel Disease, Crohn’s Disease, Irritable bowel syndrome'
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>

<style lang="scss">
  .info-graphics {
    .info-graphic {
      background: $pureWhite;
      padding: 30px;
      text-align: center;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 400px;
      margin: 0 auto 10px auto;

      .image {
        flex-grow: 1;
        margin-bottom: 30px;

        img {
          margin: 0 auto;
          box-shadow: rgba(0, 0, 0, 0.1) 0 0 36px;
        }
      }

      h2 {
        margin-bottom: 0;
      }

      .sub-title {
        font-size: 16px;
        line-height: 20px;
        color: $lightGrey;
        font-weight: 300;
        margin-bottom: 15px;
      }

      h3 {
        margin-bottom: 15px;
      }

      .downloads {
        display: block;

        a {
          margin: 5px;
          display: inline-block;
          border-radius: 100%;
          text-decoration: none;
        }
      }
    }
  }

  @media screen and (min-width: $desktop) {
    .info-graphics {
      display: flex;
      flex-direction: column;

      .vertical-stretch {
        display: flex;
        justify-content: space-between;
      }

      .info-graphic {
        max-width: 32%;
        margin: 0;

        .downloads a img.flag {
          width: 40px;
        }
      }
    }
  }
</style>
