export default {
  pop: {
    value: '5,754,365',
    reference: {
      text: 'The Global Cancer Observatory (2018), Denmark, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/208-denmark-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '27.2',
    reference: {
      text: 'SUNE, P. (2019), Så meget koster det danske sundhedsvæsen, Berlingske, viewed 21 August 2020. 183 billion DKK = value in the reference. USD equivalent will depend on the exchange rate.',
      link: 'https://www.berlingske.dk/danmark/saa-meget-koster-det-danske-sundhedsvaesen'
    }
  },
  gov_exp: {
    value: 84.02,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 13.74,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '~30',
    reference: {
      text: 'JEPPESEN, P. (2014). Spectrum of Short Bowel Syndrome in Adults Intestinal Insufficiency to Intestinal Failure. Journal of Parenteral and Enteral Nutrition. 38(1). 8S–13S',
      link: null
    }
  },
  kidney: {
    value: '9,646',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) 2017, GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj- UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '11,511',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) 2017, GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj- UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: true,
    reference: {
      text: 'BRANDT, C. et al. (2016). Home Parenteral Nutrition in Adult Patients with Chronic Intestinal Failure: Catheter-Related Complications Over 4 Decades at the Main Danish Tertiary Referral Center. Journal of Parenteral and Enteral Nutrition. 1-8.',
      link: null
    }
  },
  reg_sbs_if: {
    value: true,
    reference: {
      text: 'FUGSLANG, K. et al. (2020). Hospitalizations in Patients with Nonmalignant Short‐Bowel Syndrome Receiving Home Parenteral Support. Nutrition in Clinical Practice. 0. 1-9',
      link: null
    }
  },
  reg_hpn: {
    value: true,
    reference: {
      text: 'BRANDT, C. et al. (2016). Home Parenteral Nutrition in Adult Patients with Chronic Intestinal Failure: Catheter-Related Complications Over 4 Decades at the Main Danish Tertiary Referral Center. Journal of Parenteral and Enteral Nutrition. 1-8.',
      link: null
    }
  },
  ref_centers: {
    value: 3,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.\n' +
        'Odense University Hospital. 2020. Paediatrics. Viewed 28 January 2021, https://en.ouh.dk/department/paediatrics/',
      link: null
    }
  },
  research_liver: {
    value: 6,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 59,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 10,
    reference: null
  },
  patient_orgs: [
    {
      name: 'HPN Association',
      type: '<abbr title="Short Bowel Syndrome with Intestinal Failure">SBS</abbr>, <abbr title="Home Parenteral Nutrition">HPN</abbr>',
      reference: {
        text: 'HPN-foreningen - en frivillig patientorganisation. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://hpn.dk'
      }
    },
    {
      name: 'CCF Association',
      type: '<abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Colitis-Crohn Foreningen. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://ccf.dk'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'MORENO, J. et al. (2001). Survey on legislation and funding of Home Artificial Nutrition in Different European Countries. Clinical Nutrition 20(2). 117-123.',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
