export default {
  pop: {
    value: '59,290,973',
    reference: {
      text: 'The Global Cancer Observatory (2018), Italy, World Health Organization, viewed 21 August 2020',
      link: 'http://gco.iarc.fr/today/data/factsheets/populations/380-italy-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '184.226',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 73.90,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 23.49,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '14.12',
    reference: {
      text: 'DIAMANTI, A. et al. (2017). Pediatric Chronic Intestinal Failure in Italy Report from the 2016 Survey on Behalf of Italian Society for Gastroenterology, Hepatology and Nutrition (SIGENP). Nutrients. 9(11).',
      link: null
    }
  },
  kidney: {
    value: '10,170',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '22,727',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: true,
    reference: {
      text: 'LEZO, A. et al. (2018). Paediatric Home Artificial Nutrition in Italy: Report from 2016 Survey on Behalf of Artificial Nutrition Network of Italian Society for Gastroenterology. Hepatology and Nutrition (SIGENP). Nutrients. 10(9). 1311.',
      link: null
    }
  },
  ref_centers: {
    value: 19,
    reference: {
      text: 'DIAMANTI, A. et al. (2017). Pediatric Chronic Intestinal Failure in Italy Report from the 2016 Survey on Behalf of Italian Society for Gastroenterology, Hepatology and Nutrition (SIGENP). Nutrients. 9(11).',
      link: null
    }
  },
  research_liver: {
    value: 10,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 105,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 5,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Un Filo per la Vita',
      type: '<abbr title="Home Artificial Nutrition">HAN</abbr>',
      reference: {
        text: 'Un filo per la vita. 2020. Homepage. Viewed 21 August 2020',
        link: 'http://www.unfiloperlavita.it'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'MORENO, J. et al. (2001). Survey on legislation and funding of Home Artificial Nutrition in Different European Countries. Clinical Nutrition 20(2). 117-123. † Indicated data found in non-English language sources. Information has been translated for inclusion.',
      link: null
    }
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
