<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <p>ATLAS is co-ordinated by a Steering Committee, comprised of expert clinicians, patient advocates, patient organisation leaders and <router-link :to="{ name: 'About' }">Takeda</router-link>, to develop and enact solutions to the <router-link :to="{ name: 'Needs' }">unmet needs</router-link> and treatment requirements of people with intestinal failure (IF).</p>
    <p>Find out more about the Steering Committee members:</p>
    <div class="committee">
      <committee-member v-for="(person, key) in committee" :key="key" :id="key" :person="person"></committee-member>
    </div>
  </div>
</template>

<script>
import CommitteeMember from '@/components/CommitteeMember'
import committee from '@/data/committee.js'

export default {
  data () {
    return {
      title: 'ATLAS Steering Committee',
      committee,
      description: 'ATLAS is co-ordinated by a Steering Committee, comprised of intestinal failure and digestive system doctors, patient advocates and Takeda.',
      keywords: 'Steering Committee, Intestinal Failure, Healthcare professionals, Takeda'
    }
  },
  components: {
    CommitteeMember
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  }
}
</script>

<style lang="scss">
  .committee {
    .member {
      position: relative;
      margin-bottom: 20px;
      background: $white;
      cursor: pointer;

      .toggle {
        position: absolute;
        -webkit-mask: url('/images/menu_close.svg') no-repeat;
        mask: url('/images/menu_close.svg') no-repeat;
        background: $darkBlue;
        top: 20px;
        right: 20px;
        width: 36px;
        height: 36px;
        transform: rotate(45deg);
        transition-duration: 0.3s;
        transition-property: transform;
      }

      img {
        margin-bottom: 0;
      }

      .text {
        flex: 1;
        padding: 20px;
        font-size: 17px;
        line-height: 20px;
        color: $darkBlue;
        text-align: center;

        h4 {
          font-size: 20px;
          line-height: 23px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        .title {
          color: $lightGreen;
        }
      }

      .bio {
        display: none;
      }

      &.expanded {
        .toggle {
          background: $white;
          transform: rotate(0deg);
        }

        .text,
        .text .title {
          color: $white;
        }

        &.blue {
          background: $lightBlue;
        }

        &.green {
          background: $lightGreen;
        }

        .bio {
          position: absolute;
          display: block;
          width: 100%;
          color: $white;
          padding: 30px;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.04em;
          z-index: 100;
          top: 100%;
          overflow-y: auto;
          max-height: 500px;
        }

        &.blue .bio {
          background: $lightBlue;
          @include scrollbars($lightBlue, $darkBlue);
        }

        &.green .bio {
          background: $lightGreen;
          @include scrollbars($lightGreen, $darkGreen);
        }

        &:nth-child(2n + 1) .bio {
          left: 0;
        }

        &:nth-child(2n) .bio {
          right: 0;
        }
      }
    }
  }

  @media screen and (min-width: $mobile) {
    .committee {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .member {
        width: calc(50% - 10px);

        &:hover {
          &.blue {
            background: $lightBlue;
          }

          &.green {
            background: $lightGreen;
          }

          .text,
          .text .title {
            color: $white;
          }
        }

        &.expanded .bio {
          width: calc(200% + 20px);
        }
      }
    }
  }

  @media screen and (min-width: $desktop) {
    .committee .member {
      display: flex;

      .text {
        padding: 40px 20px 20px 0;
        text-align: left;
      }
    }
  }
</style>
