export default function (event) {
  if (!event) {
    return
  }
  const element = event.target.tagName === 'A' ? event.target : event.target.parentElement
  if (element.tagName !== 'A') {
    return
  }
  if (!element.getAttribute('href').match(/^(?!\/[^/]).*/)) {
    return
  }
  event.preventDefault()
  event.stopPropagation()

  let name = element.href.replace(/(https?:\/\/)?(www.)?/i, '')
  if (name.indexOf('/') !== -1) {
    name = name.split('/')[0]
  }

  this.$store.dispatch('setExternalWarning', {
    url: element.href,
    name: name,
    target: element.target
  })
}
