export default {
  pop: {
    value: '7,036,852',
    reference: {
      text: 'The Global Cancer Observatory (2018), Bulgaria, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/100-bulgaria-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '4.30',
    reference: {
      text: 'Eurostat Statistics explained (2019), Current healthcare expenditure 2016 FP19, Eurostat Statistics explained, viewed 21 August 2020. 3961 million euros = value in the reference (exchange rate 29/04/2020)',
      link: 'https://ec.europa.eu/eurostat/statistics-explained/index.php?title=File:Current_healthcare_expenditure,_2016_FP19.png#filehistory'
    }
  },
  gov_exp: {
    value: 51.86,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure)',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 46.55,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: null,
    reference: null
  },
  kidney: {
    value: '13,915',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) 2017, GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '18,217',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) 2017, GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: null,
    reference: null
  },
  reg_sbs_if: {
    value: null,
    reference: null
  },
  reg_hpn: {
    value: null,
    reference: null
  },
  ref_centers: {
    value: null,
    reference: null
  },
  research_liver: {
    value: 2,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 32,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 0,
    reference: null
  },
  patient_orgs: [
    {
      name: 'NPO',
      type: 'General, not <abbr title="Short Bowel Syndrome with Intestinal Failure">SBS</abbr>/<abbr title="Chronic Intestinal Failure">CIF</abbr> specific',
      reference: {
        text: 'Национална пациентска организация. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.npo.bg'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591',
      link: null
    }
  },
  hpn_reimbursed: {
    value: null,
    reference: null
  },
  nat_guidelines_cif: {
    value: null,
    reference: null
  },
  nat_guidelines_sbs: {
    value: null,
    reference: null
  }
}
