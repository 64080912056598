<template>
    <path
      :id="country.code"
      :class="{selectable: country.data !== null, selected: country.selected}"
      stroke-miterlimit="0"
      :d="country.map"
      @click="country.data !== null ? toggleCountry(country.code) : null">
      <title>{{ country.name }}</title>
    </path>
</template>

<script>
export default {
  props: {
    country: {
      type: Object,
      required: true
    }
  },
  methods: {
    toggleCountry (code) {
      if (this.country.selected) {
        this.$store.dispatch('deselectCountry')
      } else {
        this.$store.dispatch('selectCountry', code)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  path {
    fill: $lightGrey;
    stroke: $white;

    &.selectable {
      cursor: pointer;
      fill: $grey;
    }

    &.selectable:not(.selected):hover {
      fill: $darkBlue;
    }

    &.selected {
      fill: $lightGreen;
    }
  }
</style>
