<template>
  <div class="content">
    <h1>{{ title }}</h1>
    <img srcset="/images/pages/who-we-are@2x.jpg 2x, /images/pages/who-we-are.jpg 1x" src="/images/pages/who-we-are.jpg" :alt="title" />
    <p>Takeda is a patient-focused, values-based, R&D-driven global biopharmaceutical company committed to bringing Better Health and a Brighter Future to people worldwide. Our passion and pursuit of potentially life-changing treatments for patients are deeply rooted in our distinguished history in Japan since 1781.</p>

    <p class="bold">Our mission is to strive towards Better Health and a Brighter Future for people worldwide through leading innovation in medicine.</p>

    <p class="bold">We serve the needs of our patients, wherever they are. We earn the trust of society and customers through ‘Takeda-ism’.</p>

    <p class="bold">We are recognised as best in class because of agility and innovation, qualities that help us build a steady pipeline and deliver growth, year on year.</p>

    <h2>Our priorities</h2>
    <p>We take action and make decisions by focusing on our four priorities, in order of:</p>

    <div class="priorities">
      <div class="priority">
        <div class="number">1</div>
        <div class="text">Putting the patient at the centre</div>
      </div>
      <div class="priority">
        <div class="number">2</div>
        <div class="text">Building trust with society</div>
      </div>
      <div class="priority">
        <div class="number">3</div>
        <div class="text">Reinforcing our reputation</div>
      </div>
      <div class="priority">
        <div class="number">4</div>
        <div class="text">Developing the business</div>
      </div>
    </div>

    <h2>Takeda in Gastroenterology</h2>
    <p>We believe that GI diseases are not just life-disrupting conditions, but diseases that can impact a patient’s quality of life.<sup>1,2</sup> Beyond a fundamental need for effective treatment options, we understand that improving patients’ lives also depends on their needs being recognised. With nearly 30 years of experience in gastroenterology, Takeda has made significant strides in addressing GI patient needs with treatments for inflammatory bowel disease (IBD), acid-related diseases, short bowel syndrome (SBS), and motility disorders. We are making significant strides toward closing the gap on new areas of unmet needs for patients who have celiac disease, Crohn’s disease, and acute pancreatitis, among others. Together with researchers, patient groups and more, we are working to advance scientific research and clinical medicine in GI.</p>

    <atlas-references>
      <ol>
        <li>Center for Drug Evaluation and Research (CDER) & the FDA. The Voice of the Patient/Functional Gastrointestinal Disorder; 2016. <a href="https://www.fda.gov/media/95140/download" target="_blank" @click="externalURL" rel="nofollow">https://www.fda.gov/media/95140/download</a></li>
        <li>Jones R, et al. Management of common gastrointestinal disorders: quality criteria based on patients’ views and practice guidelines. Br J Gen Pract. 2009; 59(563):e199-208.</li>
      </ol>
    </atlas-references>
  </div>
</template>

<script>
import { takeda } from '@/variables.js'
import AtlasReferences from '@/components/References'
import externalURL from '@/external-url'

export default {
  data () {
    return {
      title: 'About ' + takeda,
      description: 'Takeda is a patient-focused, values-based, R&D-driven global biopharmaceutical company committed to bringing Better Health and a Brighter Future to patients and doctors in intestinal failure',
      keywords: 'Intestinal Failure, Takeda, Biopharmaceutical, Biotech, Patient Investment'
    }
  },
  methods: {
    externalURL
  },
  components: {
    AtlasReferences
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description },
        { name: 'keywords', content: this.keywords },
        { property: 'og:title', content: this.title },
        { property: 'og:description', content: this.description }
      ]
    }
  },
  mounted () {
    document.body.classList.add('takeda')
  },
  destroyed () {
    document.body.classList.remove('takeda')
    // Second time in case this page was loaded as a pre-rendered page
    document.body.classList.remove('takeda')
  }
}
</script>

<style lang="scss">
  .priorities {

    .priority {
      background: $white;
      padding: 15px 30px;
      display: flex;
      flex-direction: column;
      max-width: 210px;
      margin: 0 auto 25px auto;

      .number {
        font-size: 43px;
        font-weight: bold;
        line-height: 59px;
        color: $takedaRed;
      }

      .text {
        margin-left: 30px;
        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  @media screen and (min-width: $mobile) {
    .priorities {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .priority {
        margin: 0 25px 25px 0;
      }
    }
  }

  @media screen and (min-width: $desktop) {
    .priorities {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
</style>
