export default {
  pop: {
    value: '66,573,503',
    reference: {
      text: 'The Global Cancer Observatory (2018), United Kingdom, World Health Organization, viewed 21 August 2020',
      link: 'https://gco.iarc.fr/today/data/factsheets/populations/826-united-kingdom-fact-sheets.pdf'
    }
  },
  health_exp: {
    value: '274.937',
    reference: {
      text: 'Calculated by using the GDP (current US$ billion) and the healthcare % share of GDP',
      link: null
    }
  },
  gov_exp: {
    value: 79.41,
    reference: {
      text: 'The World Bank, Domestic general government health expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.GHED.CH.ZS'
    }
  },
  oop_exp: {
    value: 15.96,
    reference: {
      text: 'The World Bank, Out-of-pocket expenditure (% of current health expenditure), The World Bank, viewed 21 August 2020',
      link: 'https://data.worldbank.org/indicator/SH.XPD.OOPC.CH.ZS'
    }
  },
  cif_sbs_if: {
    value: '40',
    reference: {
      text: 'KELLY, DG. et al. (2014). Short bowel syndrome: highlights of patient management, quality of life, and survival. JPEN Parenter Enteral Nutr. 38(4). 427-37.\n' +
        'SMITH, T. et al. (2016). BANS Report 2016: Artificial Nutrition Support in the UK 2005-2015 - Adult Home Parenteral Nutrition. BAPEN (British Association of Parenteral and Enteral Nutrition)',
      link: null
    }
  },
  kidney: {
    value: '8,459',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  liver: {
    value: '14,071',
    reference: {
      text: 'Institute for Health Metrics and Evaluation (IHME) (2017), GBD Compare | Viz Hub, Global Health Data Exchange, viewed 21 August 2020',
      link: 'https://vizhub.healthdata.org/gbd-compare/?fbclid=IwAR2wKFTCSeYbakfuufJbahIarj-UmejYfdHtVS9dhKcTgNDU-SM4TJPJUf0'
    }
  },
  reg_cif: {
    value: true,
    reference: {
      text: 'British Artificial Nutrition Survey 2020, BANS Reports, British Association for Parenteral and Enteral Nutrition, viewed 21 August 2020',
      link: 'https://www.bapen.org.uk/resources-and-education/publications-and-reports/bans'
    }
  },
  reg_sbs_if: {
    value: true,
    reference: {
      text: 'British Artificial Nutrition Survey 2020, BANS Reports, British Association for Parenteral and Enteral Nutrition, viewed 21 August 2020',
      link: 'https://www.bapen.org.uk/resources-and-education/publications-and-reports/bans'
    }
  },
  reg_hpn: {
    value: true,
    reference: {
      text: 'British Artificial Nutrition Survey 2020, BANS Reports, British Association for Parenteral and Enteral Nutrition, viewed 21 August 2020',
      link: 'https://www.bapen.org.uk/resources-and-education/publications-and-reports/bans'
    }
  },
  ref_centers: {
    value: 2,
    reference: {
      text: 'St Mark’s Hospital and Academic Institute (2020), Intestinal Failure Unit, St Mark’s Hospital and Academic Institute, viewed 21 August 2020, http://www.stmarkshospital.nhs.uk/services-a-z/intestinal-failure-unit/\n' +
        'Salford Royal NHS Foundation Trust 2020, Intestinal Failure Unit - Ward H8, Salford Royal NHS Foundation Trust, viewed 21 August 2020. https://www.srft.nhs.uk/about-us/depts/ifu/',
      link: null
    }
  },
  research_liver: {
    value: 12,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Liver Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=1232&map.y=392&recrs=adef&cond=Liver+Failure&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=2351'
    }
  },
  research_renal: {
    value: 175,
    reference: {
      text: 'U.S National Library of Medicine, Clinical Trials.Gov, Renal Failure, U.S National Institute of Health, 2020, viewed 14 August 2020',
      link: 'https://clinicaltrials.gov/ct2/results/map/click?map.x=887&map.y=280&recrs=adef&cond=Renal+Insufficiency&strd_s=12%2F08%2F2010&strd_e=12%2F08%2F2020&mapw=1715'
    }
  },
  research_intestinal: {
    value: 8,
    reference: null
  },
  patient_orgs: [
    {
      name: 'Patients on Intravenous and Naso-gastric Nutrition Treatment (PINNT)',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Gastrointestinal">GI</abbr>, <abbr title="Home Artificial Nutrition">HAN</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Patients on Intravenous and Naso-gastric Nutrition Treatment. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://pinnt.com/About-Us.aspx'
      }
    },
    {
      name: 'Short Bowel Survivors and Friends',
      type: '<abbr title="Short Bowel Syndrome">SBS</abbr>, <abbr title="Gastrointestinal">GI</abbr>, <abbr title="Home Artificial Nutrition">HAN</abbr>, <abbr title="Inflammatory Bowel Disease">IBD</abbr>',
      reference: {
        text: 'Short Bowel Survivor & friends. 2020. Homepage. Viewed 21 August 2020',
        link: 'https://www.shortbowelsurvivor.co.uk'
      }
    }
  ],
  hpn_available: {
    value: true,
    reference: {
      text: 'PIRONI, L. et al. (2020). Home parenteral nutrition provision modalities for chronic intestinal failure in adult patients: An international survey. Clinical Nutrition. 39(2). 585-591.',
      link: null
    }
  },
  hpn_reimbursed: {
    value: true,
    reference: {
      text: 'British Intestinal Failure Alliance (2019), Position Statement: Home Parenteral Support (HPS). British Association for Parenteral and Enteral Nutrition, viewed 21 August 2020',
      link: 'https://www.bapen.org.uk/pdfs/bifa/position-statements/position-statement-on-hps.pdf'
    }
  },
  nat_guidelines_cif: {
    value: true,
    reference: {
      text: 'British Intestinal Failure Alliance (2018), Use of peptide growth factors for adult patients with intestinal failure. British Association for Parenteral and Enteral Nutrition, viewed 21 August 2020',
      link: 'https://www.bapen.org.uk/pdfs/bifa/position-statements/use-of-peptide-growth-factors-for-adult-patients-with-intestinal-failure-draft.pdf'
    }
  },
  nat_guidelines_sbs: {
    value: true,
    reference: {
      text: 'British Intestinal Failure Alliance (2018), Use of peptide growth factors for adult patients with intestinal failure. British Association for Parenteral and Enteral Nutrition, viewed 21 August 2020',
      link: 'https://www.bapen.org.uk/pdfs/bifa/position-statements/use-of-peptide-growth-factors-for-adult-patients-with-intestinal-failure-draft.pdf'
    }
  }
}
